import * as React from 'react';
import { CardHeader, CardHeaderProps, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCardHeader = styled((props: CardHeaderProps) => {
    return <CardHeader {...props} titleTypographyProps={{ variant: 'h6', align: 'left' }} />;
})(({ theme }) => {
    //имена параметров отличаются от sx и соответствуют именам css
    return {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
        marginBottom: theme.spacing(2),
    };
});

export const StyledCard = styled(Card)(({ theme }) => {
    //имена параметров отличаются от sx и соответствуют именам css
    return {
        [theme.breakpoints.up('xs')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.spacing(15),
            marginRight: theme.spacing(15),
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: theme.spacing(30),
            marginRight: theme.spacing(30),
        },
    };
});
