import React from 'react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';

interface IProps {
    name: string;
    value: string;
    label?: string;
    fullWidth?: boolean;
    multiline?: boolean;
}

export const FormTextInputStub: React.FC<IProps> = ({ name, value, label, fullWidth = false, multiline = true }) => {
    return (
        <FormControl variant="outlined" fullWidth={fullWidth} disabled={true}>
            <InputLabel htmlFor={name} disabled={true}>
                {label}
            </InputLabel>
            <OutlinedInput
                id={name}
                name={name}
                value={value}
                label={label} //It is only used for layout. The actual labelling is handled by InputLabel
                type="text"
                multiline={multiline}
                sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'dashed',
                        borderWidth: '1px',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                        color: '#000000',
                        WebkitTextFillColor: '#000000',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: 'rgba(0, 0, 0, 0.015)',
                    },
                }}
            />
            <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }}>
                {' '}
            </FormHelperText>
        </FormControl>
    );
};
