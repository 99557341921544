import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormTextInput } from 'components/inputs/FormTextInput';
import { FormPassword } from 'components/inputs/FormPassword';
import { FormCheckBox } from 'components/inputs/FormCheckBox';
import { LoginButton } from 'components/buttons/LoginButton';
import { authApi } from 'api';
import { useAppActionsContext } from 'AppContext';
import { useNotification } from 'components/notify';
import { useIsMounted } from 'lib/hooks';
import { auth, LoginStatus } from 'lib/auth';
import { dateToHHMMSS } from 'lib/formatDate';
import { stat } from 'lib/stat';

interface IFormData {
    login: string;
    password: string;
    remember: boolean;
}

const validationSchema = yup.object().shape({
    login: yup.string().required(`поле не заполнено`),
    password: yup.string().required(`поле не заполнено`),
});

const Login: React.FC = () => {
    const { notifyApiError } = useNotification();
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные запроса
    const [content, setContent] = React.useState({ message: '' });
    //действия из контекста
    const { ctxSetUserData } = useAppActionsContext();
    //данные
    const defaultValues: IFormData = {
        login: '',
        password: '',
        remember: false,
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<IFormData>({ defaultValues, resolver: yupResolver(validationSchema) });

    const onSubmit = (values: IFormData) => {
        setIsLoading(true);
        setContent((prev) => ({ ...prev, message: '' }));
        authApi
            .login({ username: values.login, password: values.password })
            .then((res) => {
                const { retval, token, ttl, repeatPeriod, expiresAt } = res.data;
                if (isMounted.current) {
                    auth.setIsRemember(values.remember);
                    if (retval === LoginStatus.SUCCESS) {
                        auth.setToken(res.data.accessToken, res.data.refreshToken);
                        const { username, firstname, lastname, permissions, id, commonName } = res.data.info;
                        ctxSetUserData({ id, username, firstname, lastname, permissions, commonName });
                        stat.userId = id;
                        navigate('/admin', { replace: true });
                    } else if (retval === LoginStatus.PASSWORD_EXPIRED) {
                        navigate('/auth/password-expired');
                    } else if (retval === LoginStatus.CODE_SEND) {
                        auth.setFactor2(token, ttl, repeatPeriod);
                        navigate('/auth/login-by-factor2-code');
                    } else if (retval === LoginStatus.LOGIN_LOCKED) {
                        setContent((prev) => ({ ...prev, message: 'логин временно заблокирован' }));
                    } else if (retval === LoginStatus.FAILED_CREDENTIALS) {
                        const message = expiresAt
                            ? `нет авторизации, блокировка до ${dateToHHMMSS(new Date(expiresAt))}`
                            : 'нет авторизации';
                        setContent((prev) => ({ ...prev, message }));
                    } else if (retval === LoginStatus.NOT_AUTHORIZED) {
                        setContent((prev) => ({ ...prev, message: 'нет доступа' }));
                    }
                }
            })
            .catch((error) => {
                stat.userId = 0;
                notifyApiError(error);
            })
            .finally(() => {
                //auth.setIsRemember(isRemember);
                if (isMounted.current) setIsLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                }}
            >
                <Typography
                    align="left"
                    component="p"
                    variant="h6"
                    sx={{
                        fontWeight: 500,
                        //opacity: 0.7,
                        mb: 2,
                    }}
                >
                    Войти
                </Typography>

                <FormTextInput control={control} name="login" invalidText={errors.login?.message} label="Логин" />
                <Box sx={{ mb: 1 }} />

                <FormPassword control={control} name="password" invalidText={errors.password?.message} label="Пароль" />

                <Typography
                    variant="subtitle2"
                    align="left"
                    sx={{
                        cursor: 'pointer',
                        color: 'primary.main',
                    }}
                    onClick={() => navigate('/auth/start-reset-password')}
                >
                    Я забыл пароль или не могу войти
                </Typography>

                <FormCheckBox control={control} name="remember" label="Запомнить" />

                <LoginButton title="ВОЙТИ" isLoading={isLoading} iconName="login" />

                {content.message && (
                    <Typography
                        variant="subtitle2"
                        align="center"
                        sx={{
                            color: 'error.main',
                            mt: 1,
                            fontWeight: 700,
                        }}
                    >
                        {content.message}
                    </Typography>
                )}
            </Box>
        </form>
    );
};

export default Login;
