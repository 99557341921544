/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/lines-between-class-members */
import { cloneDeep } from 'lodash';

import { TDefaultSelectParams, TFilterValues } from './types';

export type TListStorageParams = {
    page: number;
    rowsPerPage: number;
    sortField?: string;
    sortOrder?: 'asc' | 'desc';
    filterValues: TFilterValues;
};

class ListStorage {
    private storage: Record<string, TListStorageParams> = {};
    private storageDefaults: Record<string, TListStorageParams> = {};

    private initParams(listName: string) {
        if (this.storage[listName]) return;
        if (this.storageDefaults[listName]) {
            this.storage[listName] = cloneDeep(this.storageDefaults[listName]);
            return;
        }
        this.storage[listName] = {
            page: 0,
            rowsPerPage: 10,
            filterValues: [],
        };
    }

    setDefaults(listName: string, params?: TDefaultSelectParams) {
        if (!params) return;
        if (this.storageDefaults[listName]) return;
        this.storageDefaults[listName] = { ...cloneDeep(params), filterValues: [] };
    }

    getParams(listName: string) {
        this.initParams(listName);
        return this.storage[listName];
    }

    setPage(listName: string, page: number) {
        this.initParams(listName);
        this.storage[listName].page = page;
    }

    setRowsPerPage(listName: string, rowsPerPage: number) {
        this.initParams(listName);
        this.storage[listName].rowsPerPage = rowsPerPage;
    }

    setSortField(listName: string, sortField: string) {
        this.initParams(listName);
        this.storage[listName].sortField = sortField;
    }

    setSortOrder(listName: string, sortOrder: 'asc' | 'desc') {
        this.initParams(listName);
        this.storage[listName].sortOrder = sortOrder;
    }

    setFilters(listName: string, filterValues: TFilterValues) {
        this.initParams(listName);
        this.storage[listName].filterValues = cloneDeep(filterValues);
    }
}

export const listStorage = new ListStorage();
