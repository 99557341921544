import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Drawer,
    Box,
    Toolbar,
    List,
    Divider,
    ListItem,
    ListItemProps,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItemTextProps,
} from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useLayoutWidth } from 'lib/layoutWidthHook';

import * as appConst from 'app.const';
import { routes } from 'routes/routes';
import { useAppStateContext } from 'AppContext';
import { isUndefined } from 'lodash';

const StyledListItem = styled((props: ListItemProps) => {
    return <ListItem {...props} disableGutters={true} />;
})((/*{ theme }*/) => {
    //имена параметров отличаются от sx и соответствуют именам css
    return {
        paddingTop: 0,
        paddingBottom: 0,
    };
});

const StyledListItemButton = styled(ListItemButton)(({ theme }) => {
    //имена параметров отличаются от sx и соответствуют именам css
    return {
        //marginBottom: theme.spacing(1.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1.5),
        paddingRight: 0,
        '&:hover, &:focus': {
            backgroundColor: appConst.drawerHoverBackgroundColor,
        },
        '&.MuiListItemButton-root.Mui-selected': {
            color: appConst.drawerSelectedColor,
        },
        maxWidth: appConst.drawerWidth,
    };
});

const StyledListItemText = styled((props: ListItemTextProps) => {
    return (
        <ListItemText
            {...props}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{
                noWrap: true,
                variant: 'caption',
                color: 'rgba(255,255,255,0.5)',
            }}
        />
    );
})(({ theme }) => {
    //имена параметров отличаются от sx и соответствуют именам css
    return {
        paddingLeft: theme.spacing(2),
    };
});

const StyledListItemIcon = styled(ListItemIcon)((/*{ theme }*/) => {
    //имена параметров отличаются от sx и соответствуют именам css
    return {
        color: appConst.drawerColor,
        '&:hover, &:focus': {
            color: appConst.drawerSelectedColor,
        },
        minWidth: 'auto',
        '& svg': {
            fontSize: '18px',
        },
    };
});

const StyledDrawer = styled(Drawer)((/*{ theme }*/) => {
    //имена параметров отличаются от sx и соответствуют именам css
    return {
        width: appConst.drawerWidth,
        '& .MuiDrawer-paper': {
            width: appConst.drawerWidth,
            elevation: 8,
            boxSizing: 'border-box',
            color: appConst.drawerColor,
            backgroundColor: appConst.drawerBackgroundColor,
            overflow: 'hidden', //иначе отображался горизонтальный скролл, из-за маркера вложенного меню
        },
    };
});

interface IProps {
    handleDrawerToggle: () => void;
    drawerState: boolean;
}

export const Navbar: React.FC<IProps> = ({ handleDrawerToggle, drawerState }) => {
    const navigate = useNavigate();
    const { desktopMode } = useLayoutWidth(drawerState);
    //состояние вложенного меню
    const [nestedState, setNestedState] = React.useState<Record<string, boolean>>({});
    //данные из контекста
    const { permissions } = useAppStateContext();

    const activePath = window.location.pathname.split('/').pop();

    //подготовить массив элементов меню
    const menu: JSX.Element[] = [];
    for (let i = 0; i < routes.length; i++) {
        const item = routes[i];
        if (!item.isHiddenInMenu) {
            const userAccess = permissions && permissions[item.path];
            if ((permissions && isUndefined(permissions[item.path])) || (userAccess && userAccess.read)) {
                //если это обычный элемент, без вложенности
                if (!item.nested && !!item.component && item.path) {
                    menu.push(
                        <StyledListItem
                            key={item.path}
                            onClick={() => {
                                if (!desktopMode) handleDrawerToggle();
                                //setActivePath(item.path); активность будем определять автоматом по текущему path
                                navigate(item.path);
                            }}
                        >
                            <StyledListItemButton selected={item.path === activePath}>
                                <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                                <StyledListItemText primary={item.label} />
                            </StyledListItemButton>
                        </StyledListItem>
                    );
                }

                //если это элемент со вложенностью
                else if (item.nested) {
                    const isOpened = nestedState[item.path] || false;

                    //добавить корневой элемент
                    menu.push(
                        <StyledListItem
                            key={item.path}
                            onClick={() => {
                                //меняем сохраненный признак развернутого меню
                                const newNestedState = { ...nestedState };
                                newNestedState[item.path] = !isOpened;
                                setNestedState(newNestedState);
                            }}
                        >
                            <StyledListItemButton selected={item.path === activePath}>
                                {!!item.icon && <StyledListItemIcon>{item.icon}</StyledListItemIcon>}
                                <StyledListItemText
                                    primary={item.label}
                                    secondary={item.secondaryLabel || ''}
                                    {...(!item.icon && { sx: { pl: 0 } })}
                                />
                                <KeyboardArrowDownIcon
                                    sx={{
                                        mr: 1,
                                        opacity: isOpened ? 1 : 0,
                                        transform: isOpened ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '0.2s',
                                    }}
                                />
                            </StyledListItemButton>
                        </StyledListItem>
                    );

                    //добавить все вложенные элементы
                    if (isOpened) {
                        for (let j = 0; j < item.nested.length; j++) {
                            const itemNested = item.nested[j];
                            if (!itemNested.isHiddenInMenu) {
                                menu.push(
                                    <StyledListItem
                                        key={`${item.path}-${itemNested.path}`}
                                        onClick={() => {
                                            if (!desktopMode) handleDrawerToggle();
                                            navigate(itemNested.path);
                                        }}
                                    >
                                        <StyledListItemButton sx={{ pl: 3 }} selected={itemNested.path === activePath}>
                                            <StyledListItemIcon>{itemNested.icon}</StyledListItemIcon>
                                            <StyledListItemText primary={itemNested.label} />
                                        </StyledListItemButton>
                                    </StyledListItem>
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    const drawer = (
        <React.Fragment>
            <Toolbar />
            <Divider />
            <List>{menu}</List>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {/* drawer для варианта мобильного приложения */}
            {!desktopMode && (
                <Box component="nav">
                    <StyledDrawer
                        variant="temporary"
                        open={!drawerState} //т.к. начальное состояние при старте = true
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </StyledDrawer>
                </Box>
            )}

            {/* drawer для варианта desktop */}
            {/* используется display, иначе не работает эффект анимации */}
            <Box component="nav" sx={{ display: desktopMode && drawerState ? 'block' : 'none' }}>
                <StyledDrawer variant="persistent" open={drawerState}>
                    {drawer}
                </StyledDrawer>
            </Box>
        </React.Fragment>
    );
};
