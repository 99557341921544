import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';

interface IProps<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    placeholder?: string;
    invalidText?: string;
    validText?: string;
    type?: 'text' | 'password';
    disabled?: boolean;
    fullWidth?: boolean;
    multiline?: boolean;
    minRows?: number;
    size?: 'small' | 'medium';
    showHelper?: boolean;
}

export const FormTextInput = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label,
    placeholder,
    invalidText,
    validText = ' ',
    disabled = false,
    type = 'text',
    fullWidth = false,
    multiline = false,
    minRows = 2,
    size = 'medium',
    showHelper = true,
}: IProps<T>) => {
    const isError = !!invalidText;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" fullWidth={fullWidth} error={isError} disabled={disabled} size={size}>
                    <InputLabel htmlFor={name}>{label}</InputLabel>
                    <OutlinedInput
                        //disabled={disabled}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        type={type}
                        multiline={multiline}
                        minRows={minRows}
                        onChange={onChange}
                        value={value}
                        //fullWidth={true}
                        label={label} //It is only used for layout. The actual labelling is handled by InputLabel
                    />
                    {showHelper && (
                        <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }}>
                            {isError ? invalidText : validText}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
