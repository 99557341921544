import React from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import { useAppStateContext, useAppActionsContext } from 'AppContext';
import { useLayoutWidth } from 'lib/layoutWidthHook';
import { stat } from 'lib/stat';
import { auth } from 'lib/auth';
import { styles } from './styles';

//const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

interface IProps {
    handleDrawerToggle: () => void;
    drawerState: boolean;
}

export const Appbar: React.FC<IProps> = ({ handleDrawerToggle, drawerState }) => {
    const navigate = useNavigate();
    const trigger = useScrollTrigger();
    const { layoutWidth, layoutMarginLeft } = useLayoutWidth(drawerState);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    //данные из контекста
    const { username } = useAppStateContext();
    //действия из контекста
    const { ctxClearUserData } = useAppActionsContext();

    //привязка положения popup меню к аватару
    const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    //отвязать popup
    const handleMenuClose = () => setAnchorEl(null);

    return (
        <React.Fragment>
            <Slide appear={false} direction="down" in={!trigger}>
                <AppBar
                    position="fixed"
                    sx={{
                        width: layoutWidth,
                        ml: layoutMarginLeft,
                    }}
                >
                    <Toolbar>
                        {/* бургер */}
                        <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
                            <MenuIcon />
                        </IconButton>

                        {/* лого */}
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} noWrap={true}>
                            Панель управления DigitalAtom Termin
                        </Typography>

                        {/* профиль */}
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                            {/* аватар с первой буквой имени пользователя */}
                            <Tooltip title="Настройки">
                                <IconButton onClick={handleAvatarClick} size="small">
                                    <Avatar sx={{ width: 32, height: 32 }}>{username[0]}</Avatar>
                                </IconButton>
                            </Tooltip>

                            {/* имя пользователя */}
                            <Typography variant="body1" component="div" noWrap={true}>
                                {username}
                            </Typography>

                            {/* popup меню */}
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleMenuClose}
                                // onClick={handleMenuClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: styles.menuPaper,
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <Avatar />
                                    Профиль
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        auth.clear();
                                        ctxClearUserData();
                                        stat.userId = 0;
                                        navigate('/auth', { replace: true });
                                    }}
                                >
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Выход
                                </MenuItem>
                            </Menu>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </Slide>
            {/* <Offset /> */}
            <Toolbar />
        </React.Fragment>
    );
};
