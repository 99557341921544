import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button } from '@mui/material';

import { DefaultButton } from './DefaultButton';

interface IProps {
    isDirty: boolean;
    onSave?: () => void;
    pathToList?: string;
    backTitle?: string;
    disabled?: boolean;
    isLoading?: boolean;
    buttonType?: 'button' | 'submit';
}

const SaveButtons: React.FC<IProps> = ({
    isDirty,
    onSave,
    pathToList,
    backTitle = 'К списку',
    disabled = false,
    isLoading = false,
    buttonType = 'button',
}) => {
    const navigate = useNavigate();

    return (
        <Stack direction="row" justifyContent={pathToList ? 'space-between' : 'flex-end'} alignItems="center">
            {!!pathToList && (
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    type="button"
                    onClick={() => navigate(pathToList, { state: { isFromEdit: true } })}
                    disabled={disabled || isLoading}
                >
                    {backTitle}
                </Button>
            )}

            <DefaultButton
                size="small"
                onClick={onSave}
                disabled={disabled || !isDirty || isLoading}
                isLoading={isLoading}
                buttonType={buttonType}
                title="Сохранить"
            />
        </Stack>
    );
};

export default SaveButtons;
