import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { FormControlLabel, Switch } from '@mui/material';

interface IProps<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    disabled?: boolean;
}

export const FormSwitch = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label = '',
    disabled = false,
}: IProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <React.Fragment>
                    {label && (
                        <FormControlLabel
                            control={<Switch checked={value || false} onChange={onChange} />}
                            label={label}
                            disabled={disabled}
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: 'body2.fontSize' } }}
                        />
                    )}
                    {!label && <Switch checked={value || false} onChange={onChange} />}
                </React.Fragment>
            )}
        />
    );
};
