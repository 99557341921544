import React from 'react';
import { Typography, Stack, Link } from '@mui/material';

export const Footer: React.FC = () => {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 2 }}>
            <Typography variant="body2" color="text.secondary" align="left" sx={{ ml: 3 }}>
                {`© ${new Date().getFullYear()}`}
                <Link
                    color="primary.dark"
                    href="https://digitalatom.ru/"
                    sx={{ fontWeight: 'bold', ml: 1 }}
                    target="_blank"
                    rel="noreferrer"
                >
                    Digital Atom
                </Link>
            </Typography>
            <Typography variant="body2" color="text.secondary" align="right" sx={{ mr: 3 }}>
                <Link color="inherit" href="https://digitalatom.ru/contacts" target="_blank" rel="noreferrer">
                    Контакты
                </Link>
            </Typography>
        </Stack>
    );
};
