import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Typography } from '@mui/material';

import { usePublicConfig } from 'lib/config';
import { stat } from 'lib/stat';
import { FormPassword } from 'components/inputs/FormPassword';
import { LoginButton } from 'components/buttons/LoginButton';
import { authApi } from 'api';
import { useNotification } from 'components/notify';
import { useIsMounted } from 'lib/hooks';

interface IFormData {
    password: string;
    confirmationPassword: string;
}

//статус исполнения запроса
const REQUEST_STATUS_EMPTY = 0; //запроса не было
const REQUEST_STATUS_DONE = 1; //запрос выполнен
const REQUEST_STATUS_ERROR = 2; //ошибка

const ResetPassword: React.FC = () => {
    const { notifyError, notifyApiError } = useNotification();
    const { ready: configReady, publicConfig } = usePublicConfig();
    const navigate = useNavigate();
    //строка query из адресной строки
    const [searchParams] = useSearchParams();
    //данные запроса проверки токена
    const [tokenData, setTokenData] = useState({ status: REQUEST_STATUS_EMPTY, token: '', message: '' });
    //данные запроса на update пароля
    const [content, setContent] = useState({ status: REQUEST_STATUS_EMPTY, message: '' });
    //состояние выполнения
    const [running, setRunning] = useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные формы ввода
    const defaultValues: IFormData = {
        password: '',
        confirmationPassword: '',
    };

    const validationSchema = yup
        .object()
        .shape({
            password: yup
                .string()
                .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
                .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`)
                .matches(publicConfig.passwordPattern as RegExp, 'Пароль слишком слабый')
                .required(`поле не заполнено`),
            confirmationPassword: yup
                .string()
                .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
                .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`)
                .required(`поле не заполнено`),
        })
        .test('myCustomTest', '', (obj) => {
            if (obj.password && obj.confirmationPassword && obj.password !== obj.confirmationPassword)
                return new yup.ValidationError('Пароли должны совпадать!', null, 'password');
            return true;
        });

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<IFormData>({ defaultValues, resolver: yupResolver(validationSchema) });

    //*************************************************************************************************************
    //прочитать и проверить токен из адреса
    useEffect(() => {
        const token = searchParams.get('token') || '';
        setRunning(true);
        authApi
            .checkResetToken({ token })
            .then((data) => {
                if (isMounted.current) {
                    //проверка токена возвращает true/false
                    if (data.data) setTokenData({ status: REQUEST_STATUS_DONE, token, message: '' });
                    else {
                        const message = 'Некорректный токен';
                        notifyError(message);
                        //а здесь просто сохраним инфо
                        setTokenData({
                            status: REQUEST_STATUS_ERROR,
                            token: '',
                            message,
                        });
                    }
                }
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                notifyApiError(error);
                if (isMounted.current) {
                    //здесь просто сохраним инфо
                    const strError = error?.response?.data?.message || '';
                    setTokenData({
                        status: REQUEST_STATUS_ERROR,
                        token: '',
                        message: error.message + (strError ? ', ' : '') + strError,
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setRunning(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //*************************************************************************************************************
    //отправить запрос на смену пароля
    const requestStartResetPasswod = (password: string) => {
        setRunning(true);
        authApi
            .resetPassword({ token: tokenData.token, password })
            .then((data) => {
                if (isMounted.current) setContent({ status: REQUEST_STATUS_DONE, message: '' });
                //событие
                stat.addEvent({ name: 'password_restore', value: { user_id: data.data.id } });
                //перейти на login
                navigate('/auth/login', { replace: true });
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    notifyApiError(error);
                    //здесь просто сохраним инфо
                    const strError = error?.response?.data?.message || '';
                    setContent({
                        status: REQUEST_STATUS_ERROR,
                        message: error.message + (strError ? ', ' : '') + strError,
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setRunning(false);
            });
    };

    const onSubmit = (values: IFormData) => {
        requestStartResetPasswod(values.password);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" align="left" sx={{ fontWeight: 'bold' }}>
                Создать новый пароль
            </Typography>

            {/* запрос еще не отправлен или ошибка */}
            {content.status === REQUEST_STATUS_EMPTY || content.status === REQUEST_STATUS_ERROR ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="body2" align="left">
                        Длина пароля должна быть не менее 8 символов. Пароль должен состоять из букв латинского алфавита
                        (A-z), арабских цифр (0-9) и специальных символов.
                    </Typography>

                    <Box sx={{ mt: 1 }}>
                        <FormPassword
                            control={control}
                            name="password"
                            invalidText={errors.password?.message}
                            placeholder="Введите новый пароль"
                            label="пароль"
                            fullWidth={true}
                            disabled={!configReady}
                        />
                    </Box>

                    <Box>
                        <FormPassword
                            control={control}
                            name="confirmationPassword"
                            invalidText={errors.confirmationPassword?.message}
                            placeholder="Повторите пароль"
                            fullWidth={true}
                            disabled={!configReady}
                        />
                    </Box>

                    {/* кнопка */}
                    <LoginButton title="СОХРАНИТЬ" isLoading={running} />
                </form>
            ) : null}
        </React.Fragment>
    );
};

export default ResetPassword;
