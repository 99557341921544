import React, { useEffect, useState, useRef } from 'react';
import EditorJS, { LogLevels } from '@editorjs/editorjs';
// @ts-ignore
import ImageTool from '@editorjs/image';
// @ts-ignore
import QuoteTool from '@editorjs/quote';
import Header from '@editorjs/header';
// @ts-ignore
import RawTool from '@editorjs/raw';
// @ts-ignore
import EmbedTool from 'components/edit/HTMLEditor/tools/EmbedTool';
import { isUndefined } from 'lodash';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { Box, FormHelperText, FormControl } from '@mui/material';

import { fileApi } from 'api';
import localization from 'components/edit/HTMLEditor/localization';
import { StyledFormControl, StyledFormLabel } from 'components/styled/form';
import { BlockType } from './types';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    invalidText?: string;
    validText?: string;
    label?: string;
    placeholder?: string;
    forceRefreshToken?: string; //если при вызове изменить значение, то принудительно создается новый редактор
    blockTypes?: BlockType[];
}

const HTMLEditor = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    invalidText,
    validText = ' ',
    label,
    placeholder,
    forceRefreshToken,
    blockTypes,
}: Props<T>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });
    const [editorJS, setEditor] = useState<EditorJS>();
    const editorActual = useRef(editorJS);
    //запомнить последнее значение editorJS
    useEffect(() => {
        editorActual.current = editorJS;
    }, [editorJS]);

    useEffect(() => {
        //освободить память от предыдущего редактора, если она еще не освобождена
        if (editorActual.current?.destroy) editorActual.current.destroy();

        //набор инструментов для нового редактора
        const tools = {
            ...((!blockTypes || blockTypes.includes(BlockType.Header)) && {
                [BlockType.Header]: {
                    // @ts-ignore
                    class: Header,
                    inlineToolbar: ['link'],
                },
            }),
            ...((!blockTypes || blockTypes.includes(BlockType.Image)) && {
                [BlockType.Image]: {
                    class: ImageTool,
                    config: {
                        // endpoints: {
                        //     byFile: `${config.api.getBaseUrl()}/files/image`,
                        //     byUrl: `${config.api.getBaseUrl()}/files/by-url`,
                        // },
                        uploader: {
                            uploadByFile(file: File) {
                                return fileApi.createImageFile(file).then((data) => data.data);
                            },
                        },
                        captionPlaceholder: 'Название изображения',
                        //additionalRequestHeaders: authStore.authHeader,
                        field: 'files',
                    },
                },
            }),
            ...((!blockTypes || blockTypes.includes(BlockType.Quote)) && {
                [BlockType.Quote]: {
                    class: QuoteTool,
                    inlineToolbar: true,
                    config: {
                        quotePlaceholder: 'Введите цитату',
                        captionPlaceholder: 'Источник или автор цитаты',
                    },
                },
            }),
            ...((!blockTypes || blockTypes.includes(BlockType.Raw)) && {
                [BlockType.Raw]: {
                    class: RawTool,
                    config: { placeholder: 'Вставьте HTML код' },
                },
            }),
            ...((!blockTypes || blockTypes.includes(BlockType.Embed)) && {
                [BlockType.Embed]: {
                    // @ts-ignore
                    class: EmbedTool,
                },
            }),
        };

        //создать новый редактор
        setEditor(
            new EditorJS({
                placeholder: placeholder || 'Напишите здесь что-нибудь...',
                autofocus: false,
                holder: `editorjs_${name}`,
                ...(!isUndefined(value) && {
                    data: { blocks: value },
                }),
                //отключить ошибку Property 'embed' is incompatible with index signature.
                // @ts-ignore
                tools,
                onChange: async () => {
                    if (editorActual.current) {
                        const data = await editorActual.current.save();
                        onChange(data.blocks);
                    }
                },
                i18n: localization,
                logLevel: 'ERROR' as LogLevels,
            })
        );

        return () => {
            //освободить память от предыдущего редактора, если она еще не освобождена
            //используется значение, сохраненное в editorActual.current, т.к. значение переменной editorJS в этот момент м.б. неопределено
            if (editorActual.current?.destroy) editorActual.current.destroy();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefreshToken]);

    const isError = !!invalidText;

    return (
        <FormControl>
            <StyledFormControl invalidText={invalidText}>
                <StyledFormLabel>{label}</StyledFormLabel>

                <Box
                    sx={{
                        px: '2rem',
                        py: '1rem',
                    }}
                    id={`editorjs_${name}`}
                />
            </StyledFormControl>

            <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }} error={isError}>
                {isError ? invalidText : validText}
            </FormHelperText>
        </FormControl>
    );
};

export default HTMLEditor;
