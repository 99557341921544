import React, { useEffect } from 'react';
import { Container, CircularProgress } from '@mui/material';
import { Virtuoso } from 'react-virtuoso';

import ErrorWarning from 'components/errors/ErrorWarning';
import { CenteredSpiner } from 'components/loaders/CenteredSpiner';
import { ItemForm } from './components/Form';
import { useLentaRead, IDataItem, sizePerPage } from './queries';

interface IProgressProps {
    bottom?: boolean;
}
const Progress: React.FC<IProgressProps> = ({ bottom = false }) => {
    const size = 30;

    return (
        <CircularProgress
            size={`${size}px`}
            thickness={4}
            sx={{
                color: 'secondary.dark',
                position: 'absolute',
                zIndex: 1000,
                left: `calc(50% - ${size / 2}px)`,
                ...(!bottom && { top: 0 }),
                ...(bottom && { bottom: 0 }),
            }}
        />
    );
};

const Lenta: React.FC = () => {
    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        isFetchingPreviousPage,
        previousPage,
        hasNextPage,
        hasPreviousPage,
        removeQueries,
        nextPage,
    } = useLentaRead();

    useEffect(() => {
        return removeQueries;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (status === 'loading') return <CenteredSpiner />;
    if (status === 'error') return <ErrorWarning error={error as Error} />;

    let content: IDataItem[] = [];
    if (data) {
        for (let i = 0; i < data.pages.length; i += 1) {
            content = content.concat(data.pages[i].data);
        }
    }

    return (
        <React.Fragment>
            <Container
                maxWidth="sm"
                sx={{
                    height: '84vh',
                    //backgroundColor: 'red',
                    '&.MuiContainer-maxWidthSm': { paddingLeft: 0, paddingRight: 0 },
                    position: 'relative',
                }}
            >
                {isFetching && isFetchingPreviousPage && <Progress />}

                <Virtuoso
                    firstItemIndex={(data?.pageParams as number[])[0] * sizePerPage || 0}
                    initialTopMostItemIndex={0}
                    // style={}
                    data={content}
                    endReached={async (index) => {
                        if (!isFetchingNextPage && index === content.length - 1 && hasNextPage) {
                            await nextPage();
                        }
                        // }
                    }}
                    startReached={
                        async (/* index */) => {
                            if (hasPreviousPage) {
                                await previousPage();
                            }
                        }
                    }
                    overscan={200}
                    itemContent={(index, item) => {
                        return <ItemForm key={item.id} content={item} />;
                    }}
                />

                {isFetching && isFetchingNextPage && <Progress bottom={true} />}
            </Container>
        </React.Fragment>
    );
};

export default Lenta;
