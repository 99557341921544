import React from 'react';
import ReactDOM from 'react-dom';

import AppLoader from 'AppLoader';
import { NotifyContextProvider } from 'components/notify';

ReactDOM.render(
    <NotifyContextProvider>
        <AppLoader />
    </NotifyContextProvider>,
    document.getElementById('root')
);
