import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { theme } from 'theme/theme';
import App from 'App';
import { config } from 'lib/config';
import { stat } from 'lib/stat';
import { axiosInstance } from 'api';
import { useNotification } from 'components/notify';
import { AppContextProvider } from './AppContext';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            notifyOnChangeProps: 'tracked',
            //staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

const AppLoader: React.FC = () => {
    const { notifyApiError } = useNotification();
    //состояние загрузки
    const [loading, setLoading] = useState(true);
    //запрет изменения состояния размонированного объекта
    const isMounted = useRef(false);

    //*************************************************************************************************************
    useEffect(() => {
        isMounted.current = true;
        const name = process.env.REACT_APP_CONFIG_FILENAME || '/config.json';
        axiosInstance
            .get(name)
            .then(({ data }) => {
                if (isMounted.current) {
                    config.init(data);
                    axiosInstance.defaults.baseURL = config.api.getBaseUrl();
                    stat.init(config.countly_app_key, config.countly_url);
                }
            })
            .catch((error) => notifyApiError(error))
            .finally(() => {
                if (isMounted.current) setLoading(false);
            });

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return null;

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <AppContextProvider>
                        <App />
                        <ReactQueryDevtools initialIsOpen={true} position="bottom-right" />
                    </AppContextProvider>
                </BrowserRouter>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default AppLoader;
