import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { TextField } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ruLocale from 'date-fns/locale/ru';

interface IProps<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    invalidText?: string;
    validText?: string;
    disabled?: boolean;
}

export const FormDateTime = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label = '',
    invalidText,
    validText = ' ',
    disabled = false,
}: IProps<T>) => {
    const isError = !!invalidText;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={DateAdapter} locale={ruLocale}>
                    <DatePicker
                        label={label}
                        value={value}
                        onChange={(v) => onChange(v)}
                        renderInput={(params) => (
                            <TextField {...params} helperText={invalidText || validText} error={isError} />
                        )}
                        disabled={disabled}
                        mask="__.__.____"
                    />
                </LocalizationProvider>
            )}
        />
    );
};
