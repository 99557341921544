import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Typography,
    Box,
    Card,
    CardContent,
    Stack,
    CircularProgress,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import { isUndefined } from 'lodash';

import { useIsMounted } from 'lib/hooks';
import { useNotification } from 'components/notify';
import { CenteredSpiner } from 'components/loaders/CenteredSpiner';
import {
    ISelectParams,
    ColumnDescription,
    TAction,
    FilterDescription,
    TSelectValues,
    TDefaultSelectParams,
    TFilterValues,
    TFilterType,
} from './types';
import { listStorage } from './listStorage';
import { ListHeader } from './ListHeader';
import { Filters, isEmptyFilter } from './Filters';

type TOrder = 'asc' | 'desc';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IProps {
    listStorageName: string;
    title: string;
    columnsDescription: ColumnDescription[];
    filtersDescription?: FilterDescription[];
    api: (params: ISelectParams) => Promise<AxiosResponse<any, any>>;
    actions?: TAction[];
    defaultSelectParams?: TDefaultSelectParams;
}

export const DataTable: React.FC<IProps> = ({
    title,
    columnsDescription,
    filtersDescription,
    api,
    listStorageName,
    actions,
    defaultSelectParams,
}) => {
    const { notifyApiError } = useNotification();
    const [isLoading, setIsLoading] = useState(false);

    //страница
    listStorage.setDefaults(listStorageName, defaultSelectParams);
    const params = listStorage.getParams(listStorageName);

    const [page, setPage] = useState(params.page);
    const listSetPage = (value: number) => {
        listStorage.setPage(listStorageName, value);
        setPage(value);
    };

    const [rowsPerPage, setRowsPerPage] = useState(params.rowsPerPage);
    const listSetRowsPerPage = (value: number) => {
        listStorage.setRowsPerPage(listStorageName, value);
        setRowsPerPage(value);
    };

    const [sortField, setSortField] = useState(params.sortField);
    const listSetSortField = (value: string) => {
        listStorage.setSortField(listStorageName, value);
        setSortField(value);
    };

    const [sortOrder, setSortOrder] = useState(params.sortOrder);
    const listSetSortOrder = (value: TOrder) => {
        listStorage.setSortOrder(listStorageName, value);
        setSortOrder(value);
    };

    //текущий набор фильтров
    const [filters, setFilters] = useState<TFilterValues>(params.filterValues);

    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //refresh
    const [refresh, setRefresh] = useState(false);
    const refreshListFunc = () => {
        if (isMounted.current) setRefresh((prev) => !prev);
    };
    //блокировка кнопок выполнения действий
    const [blockControls, setBlockControls] = useState(false);
    const blockListFunc = (v: boolean) => {
        if (isMounted.current) setBlockControls(v);
    };
    //данные
    const [content, setContent] = React.useState<{ rows: any[]; total: number }>({ rows: [], total: -1 });

    const calcPageCount = (count: number) => {
        if (count <= 0) return 0;
        return Math.ceil(count / rowsPerPage);
    };

    //*************************************************************************************************************
    useEffect(() => {
        setIsLoading(true);

        //сформировать объект selectValues со значениями фильтров для отправки на сервер
        //для этого выполнить перенос данных из UI описания фильтров в описание для сервера
        //например: Array<{ id: number } => string[]
        const selectValues: TSelectValues = {};
        if (filtersDescription) {
            for (let i = 0; i < filters.length; i++) {
                const { key, value } = filters[i];
                if (!isEmptyFilter(value)) {
                    const description = filtersDescription.find((v) => v.key === key);
                    if (description) {
                        if (description.type === TFilterType.MULTI_SELECT)
                            selectValues[key] = (value as Array<{ id: number }>).map((v) => String(v.id));
                        else selectValues[key] = value as string;
                    }
                }
            }
        }

        //обновить таблицу с новым набором фильтров
        api({
            page: page + 1,
            sizePerPage: rowsPerPage,
            ...(!isUndefined(sortField) && { sortField }),
            ...(!isUndefined(sortOrder) && { sortOrder }),
            ...(Object.keys(selectValues).length > 0 && { filters: selectValues }),
        })
            .then(({ data }) => {
                if (isMounted.current) {
                    setContent({ rows: data.rows as unknown as any[], total: data.total });
                    const pageCount = calcPageCount(data.total);
                    if (pageCount === 0) listSetPage(0);
                    else {
                        const lastPage = pageCount - 1;
                        if (page > lastPage) listSetPage(lastPage);
                    }
                }
            })
            .catch((error) => notifyApiError(error))
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, sortField, sortOrder, filters, refresh]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (isLoading) return;
        if (newPage === page) return;
        if (newPage < 0 || newPage > calcPageCount(content.total) - 1) return;
        listSetPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isLoading) return;
        const newRowsPerPage = parseInt(event.target.value, 10);
        const numPage = page + 1;
        const newNumPage = Math.ceil(((numPage - 1) * rowsPerPage + 1) / newRowsPerPage);
        listSetPage(newNumPage - 1);
        listSetRowsPerPage(newRowsPerPage);
    };

    if (content.total < 0) return <CenteredSpiner />;

    return (
        <Box sx={{ width: '100%' }}>
            {/* заглавная панель таблицы */}
            <ListHeader title={title} actions={actions} refreshListFunc={refreshListFunc} />

            <Card>
                <CardContent>
                    {!!filtersDescription && (
                        <Box sx={{ pb: 1 }}>
                            <Filters
                                listStorageName={listStorageName}
                                filtersDescription={filtersDescription}
                                setFilters={setFilters}
                            />
                        </Box>
                    )}
                    <TableContainer>
                        <Table>
                            {/* заголовок таблицы */}
                            <TableHead sx={{ backgroundColor: 'background.default' }}>
                                <TableRow>
                                    {columnsDescription.map((column, index, columns) => {
                                        return (
                                            <TableCell
                                                key={column.dataField}
                                                align={column.align || 'left'}
                                                sx={{
                                                    //backgroundColor: index % 2 === 0 ? 'yellow' : 'cyan',
                                                    ...(index !== columns.length - 1 &&
                                                        column.width && {
                                                            width: column.width,
                                                            minWidth: column.width,
                                                        }),
                                                    fontWeight: 'bold',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {column.disableSort && column.columnTitle}
                                                {!column.disableSort && (
                                                    <TableSortLabel
                                                        active={sortField === column.dataField}
                                                        direction={sortField === column.dataField ? sortOrder : 'asc'}
                                                        onClick={() => {
                                                            if (sortField === column.dataField)
                                                                listSetSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                                                            else listSetSortOrder('asc');
                                                            listSetSortField(column.dataField);
                                                        }}
                                                    >
                                                        {column.columnTitle}
                                                    </TableSortLabel>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {content.rows.map((row) => {
                                    const rowKey = String(row.id) || uuid();

                                    return (
                                        <TableRow
                                            key={rowKey}
                                            hover={true}
                                            //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {columnsDescription.map((column) => {
                                                const value = row[column.dataField as keyof typeof row];

                                                return (
                                                    <TableCell
                                                        key={column.dataField}
                                                        align={column.align || 'left'}
                                                        sx={{
                                                            //backgroundColor: index % 2 === 0 ? 'yellow' : 'cyan',
                                                            ...(column.textOverflow &&
                                                                column.width && {
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'hidden',
                                                                    maxWidth: column.width,
                                                                }),
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        {column.format
                                                            ? column.format({
                                                                  value,
                                                                  row,
                                                                  blockListFunc,
                                                                  disabled: blockControls,
                                                                  refreshListFunc,
                                                              })
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Stack direction="row" justifyContent="flex-start" alignItems="center">
                        {(isLoading || blockControls) && content.total >= 0 && (
                            <CircularProgress size="1.5rem" sx={{ color: 'green' }} />
                        )}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 25]}
                            colSpan={3}
                            component="div"
                            count={content.total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            showFirstButton={true}
                            showLastButton={true}
                            labelDisplayedRows={({ from, to, count, page: current }) => {
                                return (
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        align="left"
                                        //sx={{color: 'primary.main',fontWeight: 'bold',}}
                                    >
                                        {`Страница: ${current + 1}, с ${from} по ${to} из ${count}`}
                                    </Typography>
                                );
                            }}
                            labelRowsPerPage={
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    align="left"
                                    //sx={{color: 'primary.main',fontWeight: 'bold',}}
                                >
                                    по:
                                </Typography>
                            }
                            backIconButtonProps={{ color: 'secondary', disabled: isLoading || page <= 0 }}
                            nextIconButtonProps={{
                                color: 'secondary',
                                disabled: isLoading || page >= calcPageCount(content.total) - 1,
                            }}
                            sx={{ flexGrow: 1 }}
                            SelectProps={{
                                disabled: isLoading,
                            }}
                        />
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
};
