import React, { useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import { Navbar } from 'components/navbar/Navbar';
import { Appbar } from 'components/appbar/Appbar';
import { Footer } from 'components/footer/Footer';
import { getRoutes } from 'routes/routes';
import { useAppStateContext } from 'AppContext';
import { useLayoutWidth } from 'lib/layoutWidthHook';

export const AdminLayoutComponent: React.FC = () => {
    const navigate = useNavigate();
    const [drawerState, setDrawerState] = useState(true);
    const { layoutWidth } = useLayoutWidth(drawerState);
    //данные из контекста
    const { id: userId } = useAppStateContext();

    //для обработки refresh браузера
    React.useEffect(() => {
        if (!userId) {
            navigate('/auth', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDrawerToggle = () => {
        setDrawerState(!drawerState);
    };

    //для обработки refresh браузера
    if (!userId) return null;

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <Navbar handleDrawerToggle={handleDrawerToggle} drawerState={drawerState} />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    //backgroundColor: 'cyan',
                    width: layoutWidth,
                }}
            >
                <Appbar handleDrawerToggle={handleDrawerToggle} drawerState={drawerState} />
                <Box
                    component="main"
                    sx={{
                        //flex: 1,
                        flexGrow: 1,
                    }}
                >
                    <Routes>
                        {getRoutes()}
                        <Route path="*" element={<Navigate to="/admin/articles" replace={true} />} />
                    </Routes>
                </Box>
                <Box component="footer">
                    <Footer />
                </Box>
            </Box>
            {/* <Outlet /> */}
        </Box>
    );
};
