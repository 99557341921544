import { useQuery, useMutation, useQueryClient } from 'react-query';

import { supportApi as itemApi } from 'api';
import { replaceAll } from 'lib/str';

//статус
export enum SupportStatus {
    NEW = 1,
    IN_PROCESS = 2,
    ARCHIVED = 3,
}

//список статусов
export const supportStatusList = [
    { id: SupportStatus.NEW, title: 'NEW' },
    { id: SupportStatus.IN_PROCESS, title: 'IN_PROCESS' },
    { id: SupportStatus.ARCHIVED, title: 'ARCHIVED' },
];

export interface IItemContent {
    name: string;
    email: string;
    subject: string;
    text: string;
    status: string;
    statusId: number;
}

export interface IItem extends Partial<IItemContent> {
    id: number;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const convertFromServer = (data: any): IItem => {
    //по строке status подобрать id для использования в контроле FormSelect
    const value = supportStatusList.find((item) => item.title === data.status);
    if (!value) throw Error(`support: convertFromServer: не найден статус=${data.status}`);

    //форматировать строку text перед выводом на экран
    const { text, ...rest } = data;
    let newText = text;
    if (newText) {
        let shablon = 'Термин (англ):';
        newText = replaceAll(newText, shablon, `\n${shablon}`);
        shablon = 'Причина:';
        newText = replaceAll(newText, shablon, `\n${shablon}`);
    }

    return { ...rest, text: newText || '', statusId: value.id };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const convertToServer = (data: any): IItem => {
    //по statusId подобрать строку status для отправки на сервер
    const value = supportStatusList.find((item) => item.id === data.statusId);
    if (!value) throw Error(`support: convertToServer: не найден id статуса=${data.statusId}`);

    return { ...data, status: value.title };
};

//ключ кеша
const queryKey = 'supportContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    return convertFromServer((await itemApi.getOne(id)).data);
}
export function useRqItemRead(id: number) {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(id),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    return convertFromServer((await itemApi.updateOne(id, convertToServer(content))).data);
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
