import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { useAppStateContext } from 'AppContext';
import { auth } from 'lib/auth';
import Login from 'views/auth/Login';
import StartResetPassword from 'views/auth/StartResetPassword';
import ResetPassword from 'views/auth/ResetPassword';
import PasswordExpired from 'views/auth/PasswordExpired';
import LoginFactor2Container from 'views/auth/LoginFactor2Container';
import LoginBackground from './LoginBackground';

const AuthLayout: React.FC = () => {
    //данные из контекста
    const { id: userId } = useAppStateContext();

    //для обработки refresh браузера
    if (userId || auth.inLoginProcess) return null;

    return (
        <React.Fragment>
            <Routes>
                <Route path="/login" element={<LoginBackground content={<Login />} />} />
                <Route path="/start-reset-password" element={<LoginBackground content={<StartResetPassword />} />} />
                <Route path="/reset-password" element={<LoginBackground content={<ResetPassword />} />} />
                <Route path="/password-expired" element={<LoginBackground content={<PasswordExpired />} />} />
                <Route
                    path="/login-by-factor2-code"
                    element={<LoginBackground content={<LoginFactor2Container />} />}
                />
                <Route path="*" element={<Navigate to="/auth/login" replace={true} />} />
            </Routes>
        </React.Fragment>
    );
};

export default AuthLayout;
