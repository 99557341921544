import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, Box } from '@mui/material';

import { DefaultButton } from './DefaultButton';

interface IProps {
    isPublished: boolean;
    isDirty: boolean;
    pathToList: string;
    onSave: () => void;
    onPublish: () => void;
    onUnpublish: () => void;
    isLoading?: boolean;
}

const SaveAndPublishButtons: React.FC<IProps> = ({
    isPublished,
    isDirty,
    pathToList,
    onSave,
    onPublish,
    onUnpublish,
    isLoading = false,
}) => {
    const navigate = useNavigate();

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    type="button"
                    onClick={() => navigate(pathToList, { state: { isFromEdit: true } })}
                    disabled={isLoading}
                >
                    К списку
                </Button>
            </Box>

            <DefaultButton
                size="small"
                onClick={onSave}
                disabled={!isDirty || isLoading}
                isLoading={isLoading}
                title={isPublished ? 'Сохранить изменения' : 'Сохранить как черновик'}
            />

            {isPublished && (
                <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={onUnpublish}
                    size="small"
                    color="warning"
                    type="button"
                    sx={{ ml: 1 }}
                >
                    Снять с публикации
                </Button>
            )}

            {!isPublished && (
                <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={onPublish}
                    size="small"
                    color="success"
                    type="button"
                    sx={{ ml: 1 }}
                >
                    Опубликовать
                </Button>
            )}
        </Stack>
    );
};

export default SaveAndPublishButtons;
