import React from 'react';
import LoginIcon from '@mui/icons-material/Login';

import { DefaultButton } from 'components/buttons/DefaultButton';

interface IProps {
    disabled?: boolean;
    isLoading?: boolean;
    title: string;
    iconName?: 'login' | '';
}

export const LoginButton: React.FC<IProps> = ({ disabled = false, isLoading = false, title, iconName = '' }) => {
    let icon: JSX.Element | undefined;
    if (iconName === 'login') icon = <LoginIcon />;

    return (
        <DefaultButton
            disabled={disabled}
            isLoading={isLoading}
            buttonType="submit"
            title={title}
            size="large"
            icon={icon}
        />
    );
};
