import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { FormControl, InputLabel, OutlinedInput, FormHelperText, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface IProps<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    placeholder?: string;
    invalidText?: string;
    disabled?: boolean;
    fullWidth?: boolean;
}

export const FormPassword = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label,
    placeholder,
    invalidText,
    disabled = false,
    fullWidth = false,
}: IProps<T>) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const isError = !!invalidText;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" fullWidth={fullWidth} error={isError} disabled={disabled}>
                    <InputLabel htmlFor={name}>{label}</InputLabel>
                    <OutlinedInput
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        type={showPassword ? 'text' : 'password'}
                        onChange={onChange}
                        value={value}
                        //fullWidth={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={label} //It is only used for layout. The actual labelling is handled by InputLabel
                    />
                    <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }}>
                        {isError ? invalidText : ' '}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};
