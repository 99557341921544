import React from 'react';
import { Route } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import {
    LocalOffer as LocalOfferIcon,
    FormatAlignLeft as FormatAlignLeftIcon,
    Security as SecurityIcon,
    BusinessCenter as BusinessCenterIcon,
    FileDownload as FileDownloadIcon,
    FormatListBulletedSharp as LentaIcon,
    Feedback as FeedbackIcon,
} from '@mui/icons-material';

import { ArticleList } from 'views/articles/ArticleList';
import ArticleEdit from 'views/articles/ArticleEdit';
import ArticleCreate from 'views/articles/ArticleCreate';
import { TagList } from 'views/tags/TagList';
import TagCreate from 'views/tags/TagCreate';
import TagEdit from 'views/tags/TagEdit';
import { SupportList } from 'views/support/SupportList';
import SupportEdit from 'views/support/SupportEdit';
import RoleCreate from 'views/role/RoleCreate';
import RoleEdit from 'views/role/RoleEdit';
import { UserList } from 'views/users/UserList';
import UserCreate from 'views/users/UserCreate';
import UserEdit from 'views/users/UserEdit';
import { RoleList } from 'views/role/RoleList';
import SecurityEdit from 'views/security/SecurityEdit';
import Lenta from 'views/lenta';
import Transfer from 'views/transfer';
import { Background } from './Background';

type TRoute = {
    isHiddenInMenu: boolean;
    icon?: JSX.Element;
    label?: string;
    secondaryLabel?: string;
    path: string;
    component?: React.FC<{}>;
    nested?: Array<{
        isHiddenInMenu: boolean;
        icon: JSX.Element;
        label: string;
        path: string;
        component: React.FC<{}>;
    }>;
};
//: TRoute[]
export const routes: TRoute[] = [
    {
        isHiddenInMenu: false,
        icon: <FormatAlignLeftIcon />,
        label: 'Статьи',
        path: 'articles',
        component: ArticleList,
    },
    {
        isHiddenInMenu: true,
        path: 'articles/:id/edit',
        component: ArticleEdit,
    },
    {
        isHiddenInMenu: true,
        path: 'articles/create',
        component: ArticleCreate,
    },
    {
        isHiddenInMenu: false,
        icon: <LocalOfferIcon />,
        label: 'Теги',
        path: 'tags',
        component: TagList,
    },
    {
        isHiddenInMenu: true,
        path: 'tags/:id/edit',
        component: TagEdit,
    },
    {
        isHiddenInMenu: true,
        path: 'tags/create',
        component: TagCreate,
    },
    {
        isHiddenInMenu: false,
        icon: <FeedbackIcon />,
        label: 'Заявки',
        path: 'support',
        component: SupportList,
    },
    {
        isHiddenInMenu: true,
        path: 'support/:id/edit',
        component: SupportEdit,
    },
    {
        isHiddenInMenu: false,
        icon: <PeopleIcon />,
        label: 'Пользователи',
        path: 'users',
        component: UserList,
    },
    {
        isHiddenInMenu: true,
        path: 'users/:id/edit',
        component: UserEdit,
    },
    {
        isHiddenInMenu: true,
        path: 'users/create',
        component: UserCreate,
    },
    {
        isHiddenInMenu: false,
        icon: <BusinessCenterIcon />,
        label: 'Роли',
        path: 'role',
        component: RoleList,
    },
    {
        isHiddenInMenu: true,
        path: 'role/:id/edit',
        component: RoleEdit,
    },
    {
        isHiddenInMenu: true,
        path: 'role/create',
        component: RoleCreate,
    },
    {
        isHiddenInMenu: false,
        label: 'Сервис',
        secondaryLabel: 'Безопасность, права, настройки, логи',
        path: 'settings', //для обеспечения проверки прав доступа к этому пункту
        nested: [
            {
                isHiddenInMenu: false,
                icon: <SecurityIcon />,
                label: 'Безопасность',
                path: 'security',
                component: SecurityEdit,
            },
            {
                isHiddenInMenu: false,
                icon: <FileDownloadIcon />,
                label: 'Импорт данных',
                path: 'transfer',
                component: Transfer,
            },
        ],
    },
    {
        isHiddenInMenu: false,
        icon: <LentaIcon />,
        label: 'Лента новостей',
        path: 'lenta',
        component: Lenta,
    },
];

export const getRoutes = (): JSX.Element[] => {
    const results: JSX.Element[] = [];

    for (let i = 0; i < routes.length; i++) {
        const item = routes[i];
        if (!item.nested && !!item.component && item.path)
            results.push(
                <Route path={item.path} element={<Background content={<item.component />} />} key={item.path} />
            );
        else if (item.nested) {
            for (let j = 0; j < item.nested.length; j++) {
                const itemNested = item.nested[j];
                results.push(
                    <Route
                        path={itemNested.path}
                        element={<Background content={<itemNested.component />} />}
                        key={itemNested.path}
                    />
                );
            }
        }
    }

    return results;
};
