import { useInfiniteQuery, useQueryClient } from 'react-query';
import { isNull } from 'lodash';

import { lentaApi as itemApi } from 'api';

export interface IDataItem {
    id: number;
    title: string;
    createdAt: Date;
    annotate: string;
}
export interface IPage {
    data: IDataItem[];
    nextPage: number | null;
    prevPage: number | null;
}
interface IData {
    pageParams: Array<undefined | number>;
    pages: IPage[];
}

//ключ кеша
const queryKey = 'lentaData';
//количество на странице
export const sizePerPage = 100;

//*************************************************************************************************************
//чтение
async function lentaRead(pageParam = 0): Promise<IPage> {
    const page = pageParam + 1;
    const response = await itemApi.getList({
        page,
        sizePerPage,
        sortField: 'title',
        sortOrder: 'asc',
    });
    const prevPage = page > 1 ? page - 1 : null;
    const nextPage = page * sizePerPage < response.data.total ? page + 1 : null;
    return {
        data: response.data.rows,
        nextPage: isNull(nextPage) ? null : nextPage - 1,
        prevPage: isNull(prevPage) ? null : prevPage - 1,
    };
}

const cacheMaxPages = 3;

export function useLentaRead() {
    const queryClient = useQueryClient();

    const { fetchNextPage, fetchPreviousPage, ...rest } = useInfiniteQuery({
        queryKey: [queryKey],
        queryFn: ({ pageParam }) => lentaRead(pageParam),
        staleTime: 0,
        getPreviousPageParam: (firstPage: IPage) => (isNull(firstPage.prevPage) ? undefined : firstPage.prevPage),
        getNextPageParam: (lastPage: IPage) => (isNull(lastPage.nextPage) ? undefined : lastPage.nextPage),
    });

    const nextPage = async () => {
        const v = queryClient.getQueryData<IData>([queryKey]);

        if (v && v.pages.length >= cacheMaxPages) {
            queryClient.setQueryData<IData>([queryKey], {
                pages: v.pages.slice(1),
                pageParams: v.pageParams.slice(1),
            });
        }

        await fetchNextPage();
    };

    const previousPage = async () => {
        const v = queryClient.getQueryData<IData>([queryKey]);

        if (v && v.pages.length >= cacheMaxPages) {
            const newV = {
                pages: v.pages.slice(0, v.pages.length - 1),
                pageParams: v.pageParams.slice(0, v.pageParams.length - 1),
            };
            queryClient.setQueryData<IData>([queryKey], newV);
        }

        await fetchPreviousPage();
    };

    return {
        ...rest,
        nextPage,
        previousPage,
        removeQueries: () => {
            queryClient.removeQueries(queryKey, { exact: true });
        },
    };
}
