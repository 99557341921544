import * as React from 'react';
import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridRow = styled((props: GridProps) => {
    return <Grid container={true} {...props} />;
})(({ theme }) => {
    return { marginTop: theme.spacing(2) };
});

export const GridCol = styled((props: GridProps) => {
    return <Grid item={true} xs={12} {...props} />;
})((/*{ theme }*/) => {
    return { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch' };
});
