import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usersApi } from 'api';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { DataTable } from 'components/table';
import { ColumnDescription, TAction } from 'components/table/types';
import { ItemActionButtons } from 'components/table/ItemActionButtons';
import { useAppStateContext } from 'AppContext';

export const UserList = () => {
    const navigate = useNavigate();
    //данные из контекста
    const { permissions } = useAppStateContext();
    const userAccess = permissions && permissions.users;

    const columnsDescription: ColumnDescription[] = [
        {
            disableSort: true,
            dataField: '#table-actions',
            columnTitle: 'Действия',
            width: '7em',
            align: 'center',
            format: (listControl) => (
                <ItemActionButtons listControl={listControl} isEdit={userAccess?.update === true} />
            ),
        },
        {
            dataField: 'id',
            columnTitle: 'ID',
            width: '5em',
        },
        {
            dataField: 'email',
            columnTitle: 'Почта',
            width: '30em',
        },
        {
            dataField: 'role',
            columnTitle: 'Роль',
            width: '12em',
        },
        {
            dataField: 'commonName',
            columnTitle: 'Псевдоним',
            width: '20em',
        },
        {
            dataField: 'status',
            columnTitle: 'Статус',
            width: '10em',
        },
        {
            dataField: 'create',
            columnTitle: 'Дата создания',
            width: '12em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
    ];

    //Добавить действия
    const actions: TAction[] = [];
    if (userAccess?.create === true)
        actions.push({
            name: 'Добавить',
            handle: () => navigate('/admin/users/create'),
        });

    return (
        <DataTable
            listStorageName="users"
            title="Список пользователей"
            columnsDescription={columnsDescription}
            api={usersApi.getList}
            actions={actions}
            defaultSelectParams={{
                page: 0,
                rowsPerPage: 10,
                sortField: 'id',
                sortOrder: 'asc',
            }}
        />
    );
};
