import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { stat } from 'lib/stat';
import { FormTextInput } from 'components/inputs/FormTextInput';
import { LoginButton } from 'components/buttons/LoginButton';
import { authApi } from 'api';
import { useNotification } from 'components/notify';
import { useIsMounted } from 'lib/hooks';

interface IFormData {
    email: string;
}

//статус исполнения запроса
const REQUEST_STATUS_EMPTY = 0; //запроса не было
const REQUEST_STATUS_DONE = 1; //запрос выполнен
const REQUEST_STATUS_ERROR = 2; //ошибка

const validationSchema = yup.object().shape({
    email: yup.string().email('Некорректный формат адреса').required(`поле не заполнено`),
});

const StartResetPassword: React.FC = () => {
    const { notifyApiError } = useNotification();
    const navigate = useNavigate();
    //данные запроса на восстановление пароля
    const [content, setContent] = useState({ status: REQUEST_STATUS_EMPTY, message: '' });
    //состояние выполнения
    const [running, setRunning] = useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные формы ввода
    const defaultValues: IFormData = {
        email: '',
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<IFormData>({ defaultValues, resolver: yupResolver(validationSchema) });

    //*************************************************************************************************************
    //отправить запрос на восстановление
    const requestStartResetPasswod = (email: string) => {
        setRunning(true);
        authApi
            .startResetPassword({ email })
            .then(() => {
                if (isMounted.current) setContent({ status: REQUEST_STATUS_DONE, message: '' });
            })
            .catch((error) => {
                notifyApiError(error);
                if (isMounted.current) {
                    //здесь просто сохраним инфо
                    setContent({
                        status: REQUEST_STATUS_ERROR,
                        message: error.message || 'ошибка сервера',
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setRunning(false);
            });
    };

    const onSubmit = (values: IFormData) => {
        //событие
        stat.addEvent({ name: 'password_forget', value: { email: values.email } });
        //запрос
        requestStartResetPasswod(values.email);
    };

    return (
        <React.Fragment>
            <IconButton size="large" color="primary" onClick={() => navigate(-1)} sx={{ alignSelf: 'start', p: 0 }}>
                <ArrowCircleLeftIcon fontSize="inherit" />
            </IconButton>

            <Typography variant="h6" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
                Восстановление доступа
            </Typography>

            {/* запрос еще не отправлен или ошибка */}
            {content.status === REQUEST_STATUS_EMPTY || content.status === REQUEST_STATUS_ERROR ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="body2" align="left">
                        Мы отправим инструкцию по восстановлению доступа на e-mail
                    </Typography>

                    <Box sx={{ mt: 3, mb: 2 }}>
                        <FormTextInput
                            control={control}
                            name="email"
                            invalidText={errors.email?.message}
                            label="email"
                            fullWidth={true}
                        />
                    </Box>

                    {/* кнопка */}
                    <LoginButton title="ОТПРАВИТЬ" isLoading={running} />
                </form>
            ) : null}

            {/* запрос выполнен */}
            {content.status === REQUEST_STATUS_DONE ? (
                <form onSubmit={() => navigate('/auth/login', { replace: true })}>
                    <Box sx={{ mb: 10 }}>
                        <Typography variant="body2" align="left">
                            Инструкция по восстановлению доступа в течение 5 минут придет вам на почту
                        </Typography>
                    </Box>

                    {/* кнопка */}
                    <LoginButton title="ПРОДОЛЖИТЬ" />
                </form>
            ) : null}
        </React.Fragment>
    );
};

export default StartResetPassword;
