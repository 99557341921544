import React from 'react';
import { isUndefined } from 'lodash';
import { Security as SecurityIcon } from '@mui/icons-material';
import { CardContent } from '@mui/material';

import ErrorWarning from 'components/errors/ErrorWarning';
import { ViewHeader } from 'components/headers/ViewHeader';
import { CenteredSpiner } from 'components/loaders/CenteredSpiner';
import { StyledCard } from 'components/styled/card';
import ItemForm from '../components/Form';
import { useRqItemRead } from '../queries';

const ItemEdit: React.FC = () => {
    //чтение
    const rqItem = useRqItemRead();

    //isLoading из useQuery срабатывает, когда идет первоначальная загрузка данных
    if (rqItem.isLoading || rqItem.isFetching) return <CenteredSpiner />;
    if (rqItem.isError) return <ErrorWarning error={rqItem.error as Error} />;
    if (isUndefined(rqItem.data)) return null;

    return (
        <React.Fragment>
            <ViewHeader title="Изменить параметры безопасности" icon={<SecurityIcon />} />
            <StyledCard>
                <CardContent>
                    {/* не используем ключ для принудительного рендера после сохранения для обновления значений контролов,
                    т.к. в данном случае будет использоваться reset(data) с новыми значениями полей */}
                    <ItemForm item={rqItem.data} />
                </CardContent>
            </StyledCard>
        </React.Fragment>
    );
};

export default ItemEdit;
