import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

import * as appConst from 'app.const';
import logo_digital_atom_media from 'assets/logo/logo_digital_atom_media.svg';
import logo_digital_atom from 'assets/logo/logo_digital_atom.svg';
import logo_rosenergoatom from 'assets/logo/logo_rosenergoatom.svg';

interface IProps {
    content: JSX.Element;
}

const LoginBackground: React.FC<IProps> = ({ content }) => {
    return (
        <Container disableGutters={true} maxWidth={false} sx={{ backgroundColor: appConst.loginScreenBackgroundColor }}>
            <Container disableGutters={true} maxWidth={false} sx={{ height: '100vh', maxWidth: { sm: '48rem' } }}>
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                    }}
                >
                    <Card sx={{ px: 1, py: 2, mb: 10, borderRadius: 3 }}>
                        <CardContent>
                            <Grid
                                container={true}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Grid item={true} xs={6} sx={{ pr: 1, height: '320px' }}>
                                    <CardMedia
                                        component="img"
                                        image={logo_digital_atom_media}
                                        alt="Картинка не доступна"
                                        sx={{
                                            maxWidth: '74%',
                                            height: 'auto',
                                        }}
                                    />

                                    <Typography
                                        align="left"
                                        component="p"
                                        sx={{
                                            mt: 1,
                                            pr: 5,
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            letterSpacing: '2px',
                                            opacity: 0.7,
                                        }}
                                    >
                                        Платформа для управления корпоративным контентом из одной точки
                                    </Typography>

                                    <Box sx={{ mt: 18 }} />

                                    <Grid
                                        item={true}
                                        container={true}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid item={true} xs={5}>
                                            <CardMedia
                                                component="img"
                                                image={logo_digital_atom}
                                                alt="Картинка не доступна"
                                                sx={{
                                                    width: '74%',
                                                    height: 'auto',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={5}>
                                            <CardMedia
                                                component="img"
                                                image={logo_rosenergoatom}
                                                alt="Картинка не доступна"
                                                sx={{
                                                    width: '74%',
                                                    height: 'auto',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item={true}
                                    xs={6}
                                    sx={{
                                        height: '320px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'stretch',
                                        pl: 1,
                                    }}
                                >
                                    {content}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </Container>
    );
};

export default LoginBackground;
