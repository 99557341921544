import React from 'react';
import {
    Stack,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useNotification } from 'components/notify';
import { TListControl } from 'components/table/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IProps {
    listControl: TListControl;
    apiDeleteOne?: (id: number) => Promise<AxiosResponse<any, any>>;
    isEdit?: boolean;
    isDelete?: boolean;
}

export const ItemActionButtons: React.FC<IProps> = ({
    apiDeleteOne,
    isEdit,
    isDelete,
    listControl: {
        row: { id },
        disabled,
        blockListFunc,
        refreshListFunc,
    },
}) => {
    const { notifyModification, notifyApiError } = useNotification();
    const location = useLocation();
    const navigate = useNavigate();

    //диалог удаления элемента
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleClickOpen = () => setOpenDialog(true);
    const handleClose = () => setOpenDialog(false);

    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            {/* редактирование элемента id */}
            {isEdit && (
                <IconButton
                    disabled={disabled}
                    size="small"
                    sx={{ '&:hover': { color: 'primary.main' }, p: 0, mr: 2 }}
                    onClick={() => navigate(`${location.pathname}/${id}/edit`, { state: { id } })}
                >
                    <BorderColorIcon fontSize="inherit" />
                </IconButton>
            )}

            {/* удаление элемента id */}
            {isDelete && apiDeleteOne && (
                <React.Fragment>
                    <IconButton
                        disabled={disabled}
                        size="small"
                        sx={{ '&:hover': { color: 'error.main' }, p: 0 }}
                        onClick={() => handleClickOpen()}
                    >
                        <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                    <Dialog open={openDialog} onClose={handleClose}>
                        <DialogTitle>Удаление</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="h6" component="span" align="center" sx={{ fontWeight: 'bold' }}>
                                    {`Элемент списка с ID: ${id} будет удален. Подтверждаете? `}
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleClose}>
                                Отмена
                            </Button>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    blockListFunc(true);
                                    apiDeleteOne(id)
                                        .then(() => {
                                            notifyModification('delete', id);
                                            refreshListFunc();
                                        })
                                        .catch((error) => notifyApiError(error))
                                        .finally(() => {
                                            blockListFunc(false);
                                        });
                                    handleClose();
                                }}
                                autoFocus={true}
                            >
                                Подтвердить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </Stack>
    );
};
