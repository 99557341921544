import React, { useContext, useMemo, useState } from 'react';

import { TPermission } from 'types/permission.type';

type TUser = {
    username: string;
    firstname: string;
    lastname: string;
    commonName: string;
    id: number;
    permissions?: TPermission;
};

const defaultUser = { username: '', firstname: '', lastname: '', commonName: '', id: 0 };

type TActions = {
    ctxSetUserData: (user: TUser) => void;
    ctxClearUserData: () => void;
    ctxRefresh: () => void;
};

const appStateContext = React.createContext<TUser>(defaultUser);
const appActionsContext = React.createContext<TActions>({
    ctxSetUserData: () => null,
    ctxClearUserData: () => null,
    ctxRefresh: () => null,
});

export function useAppStateContext() {
    return useContext(appStateContext);
}
export function useAppActionsContext() {
    return useContext(appActionsContext);
}

export const AppContextProvider: React.FC = ({ children }) => {
    const [contextData, setContextData] = useState(defaultUser);
    //используется для принудительного обновления контекста
    const [, setRefresh] = useState(false);
    const ctxRefresh = () => setRefresh((prev) => !prev);

    //*************************************************************************************************************
    //сохранить учетные данные пользователя
    function ctxSetUserData(user: TUser) {
        setContextData((prev) => ({ ...prev, ...user }));
    }

    //*************************************************************************************************************
    //очистить контекст
    function ctxClearUserData() {
        setContextData(defaultUser);
    }

    //список всех функции по работе с контекстом; будет положен в context в actions
    //создается только один раз при первом рендеринге, чтобы не было изменений в actions, вызывающих обновление контекста
    const actions = useMemo(() => ({ ctxSetUserData, ctxClearUserData, ctxRefresh }), []);

    //вернуть child компоненты, обернутые в context
    return (
        <appStateContext.Provider value={contextData}>
            <appActionsContext.Provider value={actions}>{children}</appActionsContext.Provider>
        </appStateContext.Provider>
    );
};
