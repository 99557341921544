import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { LoginButton } from 'components/buttons/LoginButton';

const PasswordExpired: React.FC = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <IconButton size="large" color="primary" onClick={() => navigate(-1)} sx={{ alignSelf: 'start', p: 0 }}>
                <ArrowCircleLeftIcon fontSize="inherit" />
            </IconButton>

            <Typography variant="h6" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
                Пароль просрочен
            </Typography>

            <form onSubmit={() => navigate('/auth/login', { replace: true })}>
                <Box sx={{ mb: 10 }}>
                    <Typography variant="body2" align="left">
                        Инструкция по восстановлению доступа в течение 5 минут придет вам на почту
                    </Typography>
                </Box>

                {/* кнопка */}
                <LoginButton title="OK" />
            </form>
        </React.Fragment>
    );
};

export default PasswordExpired;
