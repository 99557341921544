import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { useIsMounted } from 'lib/hooks';
import { FormSwitch } from 'components/inputs';
import SaveButtons from 'components/buttons/SaveButtons';
import { GridRow, GridCol } from 'components/styled/grid';
import { useNotification } from 'components/notify';
import { IItem, useRqItemUpdate } from '../queries';

interface IProps {
    item: IItem;
}

const ItemForm: React.FC<IProps> = ({ item }) => {
    const { notifySuccess, notifyApiError } = useNotification();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные
    const defaultValues = item;

    const {
        handleSubmit,
        formState: { isDirty },
        reset,
        control,
    } = useForm<IItem>({ defaultValues });

    const onSubmit = (values: IItem) => {
        rqItemUpdate.mutate(values, {
            onSuccess: (data /*, variables, context */) => {
                if (isMounted.current) {
                    reset(data);
                    notifySuccess('Данные сохранены');
                }
            },
            onError: (error) => {
                notifyApiError(error);
            },
        });
    };

    const isLoading = rqItemUpdate.isLoading;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <GridRow sx={{ borderBottom: 1, borderWidth: '1px', borderColor: 'grey.300' }}>
                <GridCol xs={6}>
                    <Typography variant="body1" align="center">
                        Роль
                    </Typography>
                </GridCol>
                <GridCol xs={3}>
                    <Typography variant="body1" align="center">
                        Время действия пароля 90 дней
                    </Typography>
                </GridCol>
                <GridCol xs={3}>
                    <Typography variant="body1" align="center">
                        Двухфакторная аутентификация
                    </Typography>
                </GridCol>
            </GridRow>

            {item.content.map((roleItem, index) => (
                <GridRow
                    key={`item-${roleItem.roleId}`}
                    sx={{ pb: 1, borderBottom: 1, borderWidth: '1px', borderColor: 'grey.300' }}
                >
                    <GridCol xs={6}>
                        <Typography variant="body1" align="left">
                            {roleItem.title}
                        </Typography>
                    </GridCol>
                    <GridCol xs={3} sx={{ alignItems: 'center' }}>
                        <FormSwitch control={control} name={`content.${index}.ttl`} />
                    </GridCol>
                    <GridCol xs={3} sx={{ alignItems: 'center' }}>
                        <FormSwitch control={control} name={`content.${index}.factor2`} />
                    </GridCol>
                </GridRow>
            ))}

            <Box sx={{ mt: 10 }}>
                <SaveButtons buttonType="submit" isDirty={isDirty} disabled={isLoading} isLoading={isLoading} />
            </Box>
        </form>
    );
};

export default ItemForm;
