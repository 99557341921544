import React from 'react';
import { Button, CircularProgress, Box } from '@mui/material';

interface IProps {
    onClick?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    buttonType?: 'button' | 'submit';
    title: string;
    size?: 'small' | 'medium' | 'large';
    icon?: JSX.Element;
}

export const DefaultButton: React.FC<IProps> = ({
    onClick,
    disabled = false,
    isLoading = false,
    buttonType = 'button',
    title,
    icon,
    size = 'large',
}) => {
    const [height, setHeight] = React.useState(0);

    //динамически измерить высоту span, включающего spinner,
    //и установить высоту этого spinner
    const measuredRef = React.useCallback((node) => {
        if (node !== null) {
            const v = node.getBoundingClientRect().height;
            setHeight(v);
        }
    }, []);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onClick) onClick();
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Button
                variant="contained"
                size={size}
                {...(buttonType !== 'submit' && { onClick: handleClick })}
                disabled={disabled || isLoading}
                type={buttonType}
                fullWidth={true}
                {...(icon && { startIcon: icon })}
            >
                <span ref={measuredRef}>{title}</span>
            </Button>
            {isLoading && (
                <CircularProgress
                    size={height}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: `-${height / 2}px`,
                        marginLeft: `-${height / 2}px`,
                    }}
                />
            )}
        </Box>
    );
};
