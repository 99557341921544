import * as appConst from 'app.const';

type TToken = string | null;

//результат логина
export enum LoginStatus {
    SUCCESS = 1, //успех
    PASSWORD_EXPIRED = 2, //пароль просрочен
    CODE_SEND = 3, //отправлен код
    LOGIN_LOCKED = 4, //логин заблокирован
    CODE_EXPIRED = 5, //код просрочен
    WRONG_CODE = 6, //некорректный код
    NOT_FINISHED = 7, //текущая сессия еще активна
    FAILED_CREDENTIALS = 8, //некорректные реквизиты
    NOT_AUTHORIZED = 9, //не авторизован (заблокирован или нет прав)
}

/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/lines-between-class-members */
class Auth {
    public inLoginProcess = false;
    accessToken: TToken = window.sessionStorage.getItem(appConst.accessTokenKey);
    refreshToken: TToken = window.sessionStorage.getItem(appConst.refreshTokenKey);
    factor2Token = '';
    factor2SessionTTL = 0;
    factor2RepeatPeriod = 0;
    isRemember: boolean | undefined = false;

    clear() {
        this.inLoginProcess = false;
        this.accessToken = null;
        this.refreshToken = null;
        this.factor2Token = '';
        this.factor2SessionTTL = 0;
        this.factor2RepeatPeriod = 0;
        this.isRemember = false;
        window.sessionStorage.removeItem(appConst.accessTokenKey);
        window.sessionStorage.removeItem(appConst.refreshTokenKey);
    }

    setToken(accessToken: TToken, refreshToken: TToken) {
        //токен всегда сохраняем
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        //записать токен в sessionStorage, если была установлена галка при логине или такой токен уже был сохранен
        if (accessToken && (this.isRemember === true || window.sessionStorage.getItem(appConst.accessTokenKey))) {
            window.sessionStorage.setItem(appConst.accessTokenKey, accessToken);
        } else {
            window.sessionStorage.removeItem(appConst.accessTokenKey);
        }
        if (refreshToken && (this.isRemember === true || window.sessionStorage.getItem(appConst.refreshTokenKey))) {
            window.sessionStorage.setItem(appConst.refreshTokenKey, refreshToken);
        } else {
            window.sessionStorage.removeItem(appConst.refreshTokenKey);
        }
    }

    setFactor2(factor2Token: string, factor2SessionTTL: number, factor2RepeatPeriod: number) {
        this.factor2Token = factor2Token;
        this.factor2SessionTTL = factor2SessionTTL;
        this.factor2RepeatPeriod = factor2RepeatPeriod;
    }

    setIsRemember(isRemember: boolean) {
        this.isRemember = isRemember;
    }
}

export const auth = new Auth();
