import React from 'react';
import { Box, MenuItem, Menu, MenuProps, IconButton, Stack, Grid } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
    DeleteForever as DeleteForeverIcon,
    FilterAltOutlined as FilterAltOutlinedIcon,
    DoneOutlineOutlined as DoneOutlineOutlinedIcon,
} from '@mui/icons-material';
import { useForm, useFieldArray } from 'react-hook-form';
import { cloneDeep } from 'lodash';

import { FormTextInput, FormMultiSelect } from 'components/inputs';
import { GridRow, GridCol } from 'components/styled/grid';
import { listStorage } from './listStorage';
import { FilterDescription, TFilterType, TFilterValue, TFilterValues } from './types';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export const isEmptyFilter = (value: TFilterValue) => {
    if (value instanceof Array) return value.length === 0;
    return !value;
};

type FormValues = {
    content: TFilterValues;
};

interface IProps {
    listStorageName: string;
    filtersDescription: FilterDescription[];
    setFilters: (x: TFilterValues) => void; //обновить таблицу с новым набором фильтров
}

export const Filters: React.FC<IProps> = ({ listStorageName, filtersDescription, setFilters }) => {
    //меню выбора фильтра
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    //сформировать default значения фильтров по динамическим значениям из listStorage
    const params = listStorage.getParams(listStorageName);
    const defaultValues: FormValues = { content: cloneDeep(params.filterValues) };

    const {
        handleSubmit,
        formState: { isDirty },
        control,
    } = useForm<FormValues>({ defaultValues });
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'content',
    });

    const onSubmit = (values: FormValues) => {
        //reset(values);
        listStorage.setFilters(listStorageName, values.content);
        setFilters(values.content);
    };

    if (!filtersDescription) return null;

    //управление меню выбора нового фильтра
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container={true} spacing={0} flexDirection="row" alignItems="flex-start">
                <Grid item={true} xs={12} md={9} lg={10} xl={11} flexDirection="column">
                    <GridRow alignItems="flex-start" sx={{ mt: 0 }}>
                        {fields.map((item, index) => {
                            const description = filtersDescription.find((v) => v.key === item.key);
                            if (!description) return null;

                            return (
                                <GridCol
                                    key={`filter-item-${item.key}`}
                                    xl={4}
                                    lg={6}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{ px: 0.5 }}
                                >
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                                        <Box sx={{ flexGrow: 1, mb: 2 }}>
                                            {description.type === TFilterType.TEXT && (
                                                <FormTextInput
                                                    control={control}
                                                    name={`content.${index}.value`}
                                                    label={description.label}
                                                    size="small"
                                                    fullWidth={true}
                                                    showHelper={false}
                                                />
                                            )}
                                            {description.type === TFilterType.MULTI_SELECT && (
                                                <FormMultiSelect
                                                    control={control}
                                                    name={`content.${index}.value`}
                                                    label={description.label}
                                                    api={description.api}
                                                    tagsLimit={10}
                                                    optionsLimit={50}
                                                    size="small"
                                                    fullWidth={true}
                                                    showHelper={false}
                                                />
                                            )}
                                        </Box>
                                        <IconButton
                                            sx={{ '&:hover': { color: 'error.main' }, p: 0, mb: 2 }}
                                            onClick={() => remove(index)}
                                        >
                                            <DeleteForeverIcon fontSize="inherit" />
                                        </IconButton>
                                    </Stack>
                                </GridCol>
                            );
                        })}
                    </GridRow>
                </Grid>

                <Grid
                    item={true}
                    xs={12}
                    md={3}
                    lg={2}
                    xl={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ px: 1, pt: 0.5 }}
                >
                    {isDirty && (
                        <IconButton type="submit" sx={{ fontSize: '36px', p: 0, mr: 2, color: 'red' }}>
                            <DoneOutlineOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    )}
                    <IconButton onClick={handleClick} sx={{ fontSize: '36px', p: 0 }}>
                        <FilterAltOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>

            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {filtersDescription.map((description) => {
                    return (
                        <MenuItem
                            key={`menu-item-${description.key}`}
                            onClick={() => {
                                if (fields.findIndex((v) => v.key === description.key) === -1) {
                                    const { key, defaultValue: value } = description;
                                    append({ key, value });
                                    handleClose();
                                }
                            }}
                            disableRipple={true}
                        >
                            {description.label}
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </form>
    );
};
