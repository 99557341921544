import React from 'react';
import { Typography, Stack, Avatar } from '@mui/material';

import * as appConst from 'app.const';

interface IProps {
    title: string;
    icon: JSX.Element;
}

export const ViewHeader: React.FC<IProps> = ({ title, icon }) => {
    return (
        <React.Fragment>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1, mb: 6 }}>
                <Typography variant="h5" align="left" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Avatar
                    sx={{
                        bgcolor: 'inherit',
                        color: appConst.formHeaderIconColor,
                        '& svg': {
                            fontSize: '38px',
                        },
                    }}
                >
                    {icon}
                </Avatar>
            </Stack>
        </React.Fragment>
    );
};
