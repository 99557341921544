import { useQuery, useMutation, useQueryClient } from 'react-query';

import { securityApi as itemApi } from 'api';

export interface IItem {
    content: Array<{
        roleId: number;
        title: string;
        ttl: boolean;
        factor2: boolean;
    }>;
}

//ключ кеша
const queryKey = 'securityData';

//*************************************************************************************************************
//чтение
async function itemRead(): Promise<IItem> {
    const response = await itemApi.get();
    return { content: response.data };
}
export function useRqItemRead() {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
async function itemUpdate(item: IItem): Promise<IItem> {
    const response = await itemApi.update({ data: item.content }); //для этого вызова сервер требует свойство data
    return { content: response.data };
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (item: IItem) => itemUpdate(item),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
