import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box } from '@mui/material';

import { supportApi } from 'api';
import { getDirtyValues } from 'lib/getEditedValues';
import { useIsMounted } from 'lib/hooks';
import { FormTextInputStub } from 'components/inputs';
import { FormSelect } from 'components/inputs/FormSelect';
import SaveButtons from 'components/buttons/SaveButtons';
import { GridRow, GridCol } from 'components/styled/grid';
import { useNotification } from 'components/notify';
import { IItem, IItemContent, useRqItemUpdate, supportStatusList } from '../queries';

interface IProps {
    id?: number;
    content?: Partial<IItemContent>;
}

const validationSchema = yup.object({
    statusId: yup.number().moreThan(1, 'некорректное значение').required(`поле не заполнено`),
});

const ItemForm: React.FC<IProps> = ({ id, content }) => {
    const { notifyModification, notifyApiError, notifySuccess } = useNotification();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные
    let defaultValues: Partial<IItemContent> = {};
    const { id: itemId, ...rest } = content as IItem;
    defaultValues = { ...rest };

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
        setValue,
        getValues,
    } = useForm<IItemContent>({ defaultValues, resolver: yupResolver(validationSchema) });

    React.useEffect(() => {
        //для установки отметки о прочтении записи напрямую вызываем api,
        //чтобы избежать перерисовки компонента при возникновении ошибки (например, при отсутствии прав)
        const stubCheck = async () => {
            if (id && content?.status === 'NEW')
                try {
                    const status = 'IN_PROCESS';
                    await supportApi.updateOne(id, { status });
                    const value = supportStatusList.find((item) => item.title === status);
                    if (value) setValue('statusId', value.id, { shouldDirty: true });
                    notifySuccess(`Заявка прочитана`);
                    // eslint-disable-next-line no-empty
                } catch (e) {}
        };
        stubCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);

    const onSubmit = (values: IItemContent) => {
        if (id) {
            rqItemUpdate.mutate(
                { id, content: getDirtyValues(dirtyFields, values) as Partial<IItemContent> },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            notifyModification('update', id);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        }
    };

    const isLoading = rqItemUpdate.isLoading;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <GridRow columnSpacing={2}>
                {/* Имя */}
                <GridCol md={4}>
                    <FormTextInputStub name="name" label="Имя" value={getValues('name')} />
                </GridCol>

                {/* Почта */}
                <GridCol md={6}>
                    <FormTextInputStub name="email" label="Почта" value={getValues('email')} />
                </GridCol>
            </GridRow>

            {/* Тема */}
            <GridRow>
                <GridCol md={10}>
                    <FormTextInputStub name="subject" label="Тема" value={getValues('subject')} />
                </GridCol>
            </GridRow>

            {/* Текст заявки */}
            <GridRow>
                <GridCol md={10}>
                    <FormTextInputStub name="text" label="Текст заявки" value={getValues('text')} />
                </GridCol>
            </GridRow>

            {/* Статус */}
            <GridRow>
                <GridCol md={4}>
                    <FormSelect
                        control={control}
                        name="statusId"
                        label="Статус"
                        invalidText={errors.statusId?.message}
                        options={supportStatusList.filter((item) => item.title !== 'NEW')}
                    />
                </GridCol>
            </GridRow>

            <Box sx={{ mt: 10 }}>
                <SaveButtons
                    buttonType="submit"
                    isDirty={isDirty}
                    pathToList="/admin/support"
                    disabled={isLoading}
                    isLoading={isLoading}
                />
            </Box>
        </form>
    );
};

export default ItemForm;
