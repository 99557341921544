import * as yup from 'yup';

//*************************************************************************************************************
//сообщения о проверке полей
export const maxLengthMessage = (count: number) => `Максимальная длина ${count}`;
export const minLengthMessage = (count: number) => `Минимальная длина ${count}`;
export const requiredMessage = 'Поле не заполнено';

//проверка строк
type TString = { maxLength?: number; minLength?: number; required?: boolean };
export const checkString = ({ maxLength, minLength, required = true }: TString) => {
    let object = yup.string();
    if (maxLength) object = object.max(maxLength, maxLengthMessage(maxLength));
    if (minLength) object = object.min(minLength, minLengthMessage(minLength));
    if (required) object = object.required(requiredMessage);

    return object;
};

//проверка пароля
type TPassword = { maxLength?: number; minLength?: number; required?: boolean; regExp?: RegExp };
export const checkPassword = ({ maxLength, minLength, required = true, regExp }: TPassword) => {
    let object = yup.string();
    object = object.optional();
    if (maxLength) object = object.max(maxLength, maxLengthMessage(maxLength));
    if (minLength) object = object.min(minLength, minLengthMessage(minLength));
    if (regExp) object = object.matches(regExp, 'Пароль слишком слабый');
    if (required) object = object.required(requiredMessage);

    return object;
};

//проверка чисел
type TNumber = { required?: boolean };
export const checkNumber = ({ required = true }: TNumber) => {
    let object = yup.number().integer();
    if (required) object = object.moreThan(0, requiredMessage);

    return object;
};
