import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@mui/material';

interface IProps<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    disabled?: boolean;
    rootStyle?: object;
}

export const FormCheckBox = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label = '',
    disabled = false,
    rootStyle,
}: IProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <React.Fragment>
                    {label && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value || false}
                                    onChange={onChange}
                                    {...(!!rootStyle && { sx: { '&.MuiCheckbox-root': rootStyle } })}
                                />
                            }
                            label={label}
                            disabled={disabled}
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: 'body2.fontSize' } }}
                        />
                    )}
                    {!label && (
                        <Checkbox
                            checked={value || false}
                            onChange={onChange}
                            {...(!!rootStyle && { sx: { '&.MuiCheckbox-root': rootStyle } })}
                        />
                    )}
                </React.Fragment>
            )}
        />
    );
};
