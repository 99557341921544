import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Stack, Typography, CardMedia, Box } from '@mui/material';
import { Forward as ForwardIcon } from '@mui/icons-material';

import formatDate from 'lib/formatDate';
import { config } from 'lib/config';
import { IDataItem } from '../queries';

interface IProps {
    content: IDataItem;
}
export const ItemForm: React.FC<IProps> = ({ content: { id, title, createdAt, annotate } }) => {
    const navigate = useNavigate();
    const images = [
        'https://api.media.digitalatom.ru/v1/uploads/202310/938077fc-05cf-4897-b0ef-26215a74c771.webp',
        'https://api.media.digitalatom.ru/v1/uploads/202310/c6aef2a2-8bcf-428c-8e79-278ccefb6d33.webp',
        'https://api.media.digitalatom.ru/v1/uploads/202310/b39ea655-1433-4a8f-96da-0dd323e6be0d.webp',
        'https://api.media.digitalatom.ru/v1/uploads/202310/e8374f64-4192-4f0f-aedf-9dd4e16b5070.webp',
        'https://api.media.digitalatom.ru/v1/uploads/202310/12c95f77-2282-4a21-9f86-9e349e785a36.webp',
    ];
    const image = images[id % 5];

    return (
        <Card
            sx={{
                backgroundColor: '#fff',
                borderRadius: 3,
                mb: 3,
                px: 0,
                boxShadow: 3,
                border: 1,
                borderColor: 'grey.200',
            }}
        >
            <CardContent
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => navigate(`/admin/articles/${id}/edit`, { state: { id } })}
            >
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch">
                    <Typography component="span" variant="subtitle2" align="left" sx={{ color: 'grey.500', mb: 1 }}>
                        {formatDate(createdAt, config.formats.onlyDate)}
                    </Typography>

                    <Typography variant="h6" align="left" sx={{ color: 'primary.dark', fontWeight: 'bold', mb: 1 }}>
                        {title}
                    </Typography>

                    <Typography component="span" variant="subtitle2" align="left" sx={{ color: 'black', mb: 1 }}>
                        {annotate}
                    </Typography>

                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            fontSize: 'body1.fontsize',
                            mb: 1,
                        }}
                    >
                        <Typography
                            fontSize="inherit"
                            align="left"
                            sx={{ flexGrow: 1, fontWeight: 700, color: 'primary.light' }}
                        >
                            ПОДРОБНЕЕ
                        </Typography>
                        <ForwardIcon
                            fontSize="inherit"
                            sx={{
                                color: 'primary.main',
                            }}
                        />
                    </Stack>

                    {image && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '18rem',
                                overflow: 'hidden',
                                borderRadius: '14px',
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={image}
                                alt="Картинка не доступна"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: '1s',
                                    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};
