import * as appConst from 'app.const';

const MainPalette = {
    primary: {
        main: appConst.primaryMainColor,
    },
    background: {
        default: appConst.backgroundDefault,
    },
};

export default MainPalette;
