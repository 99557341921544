import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, IconButton } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { TScreen } from './login.type';
import LoginFactor2 from './LoginFactor2';

const LoginFactor2Container: React.FC = () => {
    const navigate = useNavigate();
    //текущий вид экрана
    const [screen, setScreen] = useState(TScreen.SEND_CODE);

    return (
        <React.Fragment>
            <IconButton size="large" color="primary" onClick={() => navigate(-1)} sx={{ alignSelf: 'start', p: 0 }}>
                <ArrowCircleLeftIcon fontSize="inherit" />
            </IconButton>

            <Typography variant="h6" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
                Ввод одноразового кода
            </Typography>

            <LoginFactor2 key={`screen-${screen}`} screen={screen} setScreen={setScreen} />
        </React.Fragment>
    );
};

export default LoginFactor2Container;
