import React from 'react';
import { BusinessCenter as BusinessCenterIcon } from '@mui/icons-material';
import { CardContent } from '@mui/material';

import { ViewHeader } from 'components/headers/ViewHeader';
import { StyledCard, StyledCardHeader } from 'components/styled/card';
import ItemForm from '../components/Form';

const ItemEdit: React.FC = () => {
    return (
        <React.Fragment>
            <ViewHeader title="Создать тег" icon={<BusinessCenterIcon />} />
            <StyledCard>
                <StyledCardHeader title="Новая роль" />
                <CardContent>
                    <ItemForm mode="create" />
                </CardContent>
            </StyledCard>
        </React.Fragment>
    );
};

export default ItemEdit;
