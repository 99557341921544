//имя ключа access token в sessionStorage
export const accessTokenKey = 'fb835b8e-3fcc-45d8-a99b-e272hfye92bd';
//имя ключа refresh token в sessionStorage
export const refreshTokenKey = 'd57571bc-d9db-4d20-b7ea-42afkxt471d7';

//*** цвета => тема
export const primaryMainColor = '#118EFF';
export const backgroundDefault = '#f8f9fe';

//*** цвета => login
export const loginScreenBackgroundColor = '#172b4d';

//*** цвета => drawer
export const drawerBackgroundColor = '#101F33'; //фон
export const drawerColor = 'rgba(255, 255, 255, 0.7)'; //текст+иконки
export const drawerHoverBackgroundColor = 'rgba(255, 255, 255, 0.08)'; //текст+иконки: selected background
export const drawerSelectedColor = '#4fc3f7'; //текст+иконки: selected и hover

//*** цвета => формы редактирования
export const formHeaderIconColor = '#2dce89'; //иконка в заголовке формы справа

//*** drawer
//ширина drawer панели
export const drawerWidth = 250;
