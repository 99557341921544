import React from 'react';
import { Box, FormLabel } from '@mui/material';

interface IStyledBox {
    invalidText?: string;
}
export const StyledFormControl: React.FC<IStyledBox> = ({ children, invalidText }) => {
    return (
        <Box
            sx={{
                border: 1,
                borderColor: 'grey.400',
                borderRadius: '4px',
                ...(!invalidText && {
                    '&:hover': {
                        borderColor: 'black',
                    },
                    '&:focus-within': {
                        borderColor: 'primary.main',
                        borderWidth: '2px',
                        'label:only-of-type': { color: 'primary.main' },
                    },
                }),
                ...(invalidText && {
                    borderColor: 'error.main',
                    borderWidth: 'thin',
                    '&:hover, &:focus': {
                        borderWidth: '2px',
                    },
                    'label:only-of-type': { color: 'error.main' },
                }),
            }}
        >
            {children}
        </Box>
    );
};

export const StyledFormLabel: React.FC = ({ children }) => {
    return (
        <FormLabel
            sx={{
                marginLeft: '0.71em',
                marginTop: '-0.71em',
                px: '0.44em',
                zIndex: 2,
                backgroundColor: 'background.paper',
                position: 'absolute',
                fontSize: 'caption.fontSize',
            }}
        >
            {children}
        </FormLabel>
    );
};
