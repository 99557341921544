import React from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { Autocomplete, TextField, Chip, FormControl, FormHelperText, CircularProgress } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { AxiosResponse } from 'axios';

import { useIsMounted } from 'lib/hooks';
import { useNotification } from 'components/notify';
import { IGetSelectParams } from 'api';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IItem extends Record<string, any> {
    id: number;
    title: string;
}

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    placeholder?: string;
    api: (params: IGetSelectParams) => Promise<AxiosResponse<any, any>>;
    invalidText?: string;
    validText?: string;
    tagsLimit?: number;
    optionsLimit?: number;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    showHelper?: boolean;
}

export const FormMultiSelect = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label = '',
    placeholder = 'Поиск',
    api,
    invalidText,
    validText = ' ',
    tagsLimit = 10, //ограничение на количество выбранных тегов; если 0, то без ограничений
    optionsLimit = 50, //ограничение на количество элементов в выпадающем списке; если 0, то без ограничений (зависит от сервера)
    fullWidth = false,
    size = 'medium',
    showHelper = true,
}: Props<T>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });
    const [options, setOptions] = React.useState<IItem[]>([]);
    const [inputValue, setInputValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const { notifyApiError } = useNotification();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();

    const checkTagsLimit = () => !!tagsLimit && (value as IItem[]).length >= tagsLimit;

    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    params: IGetSelectParams,
                    callbackSuccess: (results: IItem[]) => void,
                    callbackError: (error: any) => void
                ) => {
                    if (isMounted.current) setLoading(true);
                    api(params)
                        .then(({ data }) => {
                            callbackSuccess(data.result);
                        })
                        .catch((error) => callbackError(error))
                        .finally(() => {
                            if (isMounted.current) setLoading(false);
                        });
                },
                400
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    React.useEffect(() => {
        const defaultSelects = (value as IItem[]).map((item) => item.id);
        if (checkTagsLimit()) return;
        fetch(
            { defaultSelects, searchText: inputValue, optionsLimit },
            (results) => {
                if (isMounted.current) {
                    // setOptions([
                    //     ...results.filter(
                    //         (itemInResults) =>
                    //             (value as IItem[]).findIndex((itemInValue) => itemInValue.id === itemInResults.id) < 0
                    //     ),
                    //     ...(value as IItem[]),
                    // ]);
                    setOptions(results);
                }
            },
            (error) => notifyApiError(error)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, inputValue, fetch]);

    const isError = !!invalidText;

    return (
        <FormControl fullWidth={fullWidth}>
            <Autocomplete
                size={size}
                id={`${name}-MultiSelect`}
                multiple={true}
                freeSolo={false}
                // autoSelect={true}
                clearOnBlur={false}
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                getOptionDisabled={(/*option*/) => checkTagsLimit()}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'reset') return;
                    setInputValue(newInputValue);
                }}
                options={options}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        placeholder={placeholder}
                        error={isError}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                renderTags={(tagsValue: IItem[], getTagProps) =>
                    tagsValue.map((option: IItem, index: number) => (
                        <Chip variant="outlined" color="info" label={option.title} {...getTagProps({ index })} />
                    ))
                }
                isOptionEqualToValue={(i1: IItem, i2: IItem) => i1.id === i2.id}
                filterSelectedOptions={true}
                filterOptions={(optionsValue: IItem[] /*, state*/) => {
                    if (checkTagsLimit()) return [];
                    return optionsValue;
                }}
                clearText="Очистить"
                closeText="Закрыть"
                loadingText="Загрузка..."
                noOptionsText="..."
                openText="Открыть"
                disableCloseOnSelect={true}
            />
            {showHelper && (
                <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }} error={isError}>
                    {isError ? invalidText : validText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
