import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Typography } from '@mui/material';

import { stat } from 'lib/stat';
import { auth, LoginStatus } from 'lib/auth';
import { msToMMSS } from 'lib/formatTime';
import { FormTextInput } from 'components/inputs/FormTextInput';
import { FormTextInputStub } from 'components/inputs/FormTextInputStub';
import { LoginButton } from 'components/buttons/LoginButton';
import { DefaultButton } from 'components/buttons/DefaultButton';
import { authApi } from 'api';
import { useAppActionsContext } from 'AppContext';
import { useNotification } from 'components/notify';
import { useIsMounted } from 'lib/hooks';
import { TScreen, TRequestStatus } from './login.type';

interface IFormData {
    code: string;
}

const validationSchema = yup.object().shape({
    code: yup.string().length(6, 'код из 6 цифр').required(`поле не заполнено`),
});

interface IProps {
    screen: TScreen;
    setScreen: (type: TScreen) => void;
}

const LoginFactor2: React.FC<IProps> = ({ screen, setScreen }) => {
    const { notifyApiError } = useNotification();
    const navigate = useNavigate();
    //данные запроса
    const [content, setContent] = useState({ status: TRequestStatus.EMPTY, message: '' });
    //состояние выполнения
    const [isLoading, setIsLoading] = useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //действия из контекста
    const { ctxSetUserData } = useAppActionsContext();
    //таймер
    const timer = useRef<NodeJS.Timer | null>(null);
    const timeLeft = useRef(screen === TScreen.SEND_CODE ? auth.factor2SessionTTL : auth.factor2RepeatPeriod); //остаток времени
    const timeMsgRef = useRef<HTMLSpanElement>(null); //ссылка на отображаемую строку времени
    //данные формы ввода
    const defaultValues: IFormData = {
        code: '',
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = useForm<IFormData>({ defaultValues, resolver: yupResolver(validationSchema) });
    const [watchCode] = watch(['code']);

    //*************************************************************************************************************
    useEffect(() => {
        const timeMsg = () => `осталось ${msToMMSS(timeLeft.current)}`;

        const checkTimer = async () => {
            if (isMounted.current) {
                let newTimeLeft = 0;
                if (timeLeft.current >= 1000) newTimeLeft = timeLeft.current - 1000;

                //если время еще не истекло, просто изменить счетчик времени
                if (newTimeLeft !== 0) {
                    timeLeft.current = newTimeLeft;
                    if (timeMsgRef.current) timeMsgRef.current.textContent = timeMsg();
                }
                //переход на экран повторного запроса
                else if (screen === TScreen.SEND_CODE) {
                    setScreen(TScreen.REPEAT_CODE);
                }
                //переход на экран базового логина
                else {
                    navigate('/auth/login', { replace: true });
                }
            }
        };

        timer.current = setInterval(checkTimer, 1000);
        if (timeMsgRef.current) timeMsgRef.current.textContent = timeMsg();

        return () => {
            if (timer.current) clearInterval(timer.current);
        };
    }, [screen, setScreen, navigate, isMounted]);

    //*************************************************************************************************************
    //отправить код в запросе на логин
    const requestLoginByFactor2Code = (code: string) => {
        setIsLoading(true);
        setContent((prev) => ({ ...prev, message: '' }));
        authApi
            .loginByFactor2Code(code)
            .then((res) => {
                const { retval } = res.data;
                if (isMounted.current) {
                    switch (retval) {
                        case LoginStatus.SUCCESS: {
                            auth.setToken(res.data.accessToken, res.data.refreshToken);
                            const { username, firstname, lastname, permissions, id, commonName } = res.data.info;
                            ctxSetUserData({ id, username, firstname, lastname, permissions, commonName });
                            stat.userId = id;
                            navigate('/admin', { replace: true });
                            break;
                        }
                        case LoginStatus.WRONG_CODE:
                            setContent({ status: TRequestStatus.DONE, message: 'некорректный код' });
                            break;
                        case LoginStatus.LOGIN_LOCKED:
                            //setContent({ status: TRequestStatus.DONE, message: 'логин временно заблокирован' });
                            navigate('/auth/login', { replace: true });
                            break;
                        case LoginStatus.CODE_EXPIRED:
                            setContent({ status: TRequestStatus.DONE, message: 'код просрочен' });
                            break;
                        default:
                            setContent({ status: TRequestStatus.DONE, message: 'ошибка аутентификации' });
                    }
                }
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    stat.userId = 0;
                    notifyApiError(error);
                    //здесь просто сохраним инфо
                    setContent({
                        status: TRequestStatus.ERROR,
                        message: /*error.message ||*/ 'ошибка аутентификации',
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
    };

    //*************************************************************************************************************
    //отправить повторный запрос factor2 кода
    const requestRepeatFactor2Code = () => {
        setIsLoading(true);
        setContent((prev) => ({ ...prev, message: '' }));
        authApi
            .repeatFactor2Code()
            .then((res) => {
                const { retval } = res.data;
                if (isMounted.current) {
                    switch (retval) {
                        case LoginStatus.CODE_SEND:
                            auth.setFactor2(res.data.token, res.data.ttl, res.data.repeatPeriod);
                            setScreen(TScreen.SEND_CODE);
                            break;
                        case LoginStatus.LOGIN_LOCKED:
                            setContent({ status: TRequestStatus.DONE, message: 'логин временно заблокирован' });
                            break;
                        case LoginStatus.NOT_FINISHED:
                            setContent({ status: TRequestStatus.DONE, message: 'старый код еще действует' });
                            break;
                        default:
                            setContent({ status: TRequestStatus.DONE, message: 'ошибка аутентификации' });
                    }
                }
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    stat.userId = 0;
                    notifyApiError(error);
                    //здесь просто сохраним инфо
                    setContent({
                        status: TRequestStatus.ERROR,
                        message: /*error.message ||*/ 'ошибка аутентификации',
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
    };

    //Если редактируется код, то убрать message
    useEffect(() => {
        setContent((prev) => ({ ...prev, message: '' }));
    }, [watchCode]);

    const onSubmit = (values: IFormData) => {
        requestLoginByFactor2Code(values.code);
    };

    return (
        <React.Fragment>
            {screen === TScreen.SEND_CODE && (
                <Typography variant="body2" align="left" sx={{ mb: 2 }}>
                    Код был отправлен на ваш e-mail
                </Typography>
            )}

            {screen !== TScreen.SEND_CODE && (
                <Typography variant="body2" align="left">
                    Корректный код не был введен. Попробуйте получить новый.
                </Typography>
            )}

            <Typography variant="caption" align="right" ref={timeMsgRef} sx={{ mt: 3 }}>
                ...
            </Typography>

            {screen === TScreen.SEND_CODE && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ mt: 0, mb: 2 }}>
                        <FormTextInput
                            control={control}
                            name="code"
                            invalidText={errors.code?.message}
                            label="код"
                            fullWidth={true}
                            disabled={isLoading}
                        />
                    </Box>

                    {/* кнопка */}
                    <LoginButton disabled={isLoading} title="ОТПРАВИТЬ" isLoading={isLoading} />
                </form>
            )}

            {screen !== TScreen.SEND_CODE && (
                <React.Fragment>
                    <Box sx={{ mt: 0, mb: 2 }}>
                        <FormTextInputStub value="..." name="code" label="код" fullWidth={true} />
                    </Box>

                    {/* кнопка */}
                    <DefaultButton
                        disabled={isLoading}
                        title="ПОЛУЧИТЬ ПОВТОРНО"
                        isLoading={isLoading}
                        onClick={() => requestRepeatFactor2Code()}
                    />
                </React.Fragment>
            )}

            {content.message && (
                <Typography variant="body2" align="center" color="error.main" sx={{ my: 1 }}>
                    {content.message}
                </Typography>
            )}
        </React.Fragment>
    );
};

export default LoginFactor2;
