import { isValid, format, parseISO, toDate } from 'date-fns';
import { ru } from 'date-fns/locale';

const formatDate = (value: string | Date | number, dateFormat: string): string => {
    let result = '';
    try {
        let date: Date;
        if (typeof value === 'string') date = parseISO(value);
        else if (typeof value === 'number') date = toDate(value);
        else date = value;

        result = isValid(date) ? format(date, dateFormat, { locale: ru }) : '';
        // eslint-disable-next-line no-empty
    } catch {}

    return result;
};

// 2021-09-13 09:11:07.509 => '11:07'
export function dateToHHMMSS(date: Date) {
    if (!(date instanceof Date)) return '';
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
}

export default formatDate;
