import React from 'react';
import { useForm, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { isUndefined } from 'lodash';
import { v4 as uuid } from 'uuid';

import { tagsApi, usersApi } from 'api';
import { BlockType } from 'components/edit/HTMLEditor/types';
import { getDirtyValues } from 'lib/getEditedValues';
import { useIsMounted } from 'lib/hooks';
import { FormTextInput } from 'components/inputs/FormTextInput';
import SaveAndPublishButtons from 'components/buttons/SaveAndPublishButtons';
import { GridRow, GridCol } from 'components/styled/grid';
import { useNotification } from 'components/notify';
import FormHTMLEditor from 'components/edit/HTMLEditor';
import { FormDropzone, FormMultiSelect, FormSingleSelect, FormSwitch, FormDateTime } from 'components/inputs';
import { IItem, IItemContent, useRqItemUpdate, useRqItemCreate } from '../queries';

const allowBlockTypes = [BlockType.Raw, BlockType.Embed];

interface IProps {
    mode: 'edit' | 'create';
    id?: number;
    content?: Partial<IItemContent>;
}

const validationSchema = yup.object({
    //cover: yup.string().required(`поле не заполнено`),
    title: yup.string().max(256, `максимум 300 символов`).required(`поле не заполнено`),
    annotate: yup.string().max(3000, `максимум 3000 символов`).required(`поле не заполнено`),
    editorData: yup.array().min(1, 'отсутствует содержание'),
    tags: yup.array().min(1, 'отсутствуют теги'),
    author: yup.object({
        id: yup.number().integer().moreThan(0, `поле не заполнено`),
    }),
    //publishedAt: yup.date().nullable(), пока не разобрался с текстом ошибки
});

const ItemForm: React.FC<IProps> = ({ id, content, mode }) => {
    const { notifyModification, notifyApiError } = useNotification();
    const navigate = useNavigate();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    const rqItemCreate = useRqItemCreate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //если при вызове изменить значение, то принудительно создается новый редактор editorJS
    const forceRefreshToken = React.useRef(uuid());
    //данные
    let defaultValues: Partial<IItemContent> = {};
    const initDefaiults = {
        cover: '',
        coverVideo: [],
        title: '',
        annotate: '',
        editorData: [],
        tags: [],
        author: { id: 0 },
        isVideoCover: false,
        publishedAt: new Date(),
        isPublished: false,
    };
    if (mode === 'create') {
        defaultValues = initDefaiults;
    } else {
        const { id: itemId, ...rest } = content as IItem;
        defaultValues = { ...initDefaiults, ...rest };
    }

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        control,
        setValue,
        watch,
        reset,
        trigger,
        getValues,
    } = useForm<IItemContent>({ defaultValues, resolver: yupResolver(validationSchema) });
    const [watchIsVideoCover] = watch(['isVideoCover']);

    //обеспечить для onSubmit актуальные значения dirtyFields
    //это особенность использования кнопки Опубликовать
    //в одном обработчике кнопки будет установлен признак isPublished и вызван handleSubmit
    const actualDirtyFields = React.useRef(dirtyFields);
    React.useEffect(() => {
        actualDirtyFields.current = dirtyFields;
    }, [dirtyFields]);

    const onSubmit = (values: IItemContent) => {
        if (mode === 'edit' && id) {
            //при снятии с публикации не сохранять другие свойства
            let newContent: Partial<IItemContent>;
            const { isPublished: newIsPublishedValue, ...editedValues } = getDirtyValues(
                actualDirtyFields.current,
                values
            ) as Partial<IItemContent>;
            //если снять с публикации
            if (newIsPublishedValue === false) newContent = { isPublished: false };
            //в остальных случаях
            else
                newContent = {
                    ...editedValues,
                    ...(!isUndefined(newIsPublishedValue) && { isPublished: newIsPublishedValue }),
                };

            rqItemUpdate.mutate(
                { id, content: newContent },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            //изменить значение для принудительного создания нового редактора editorJS с новым текстом
                            forceRefreshToken.current = uuid();
                            // если обновление идет через invalidateQueries, то объект будет перечитан целиком, со всеми новыми значениями полей
                            // reset() - для сброса служебных флагов, например, dirtyFields
                            // другой вариант - reset(data), когда сервер возвращает полностью заполненный объект после редактирования
                            reset(data);
                            notifyModification('update', id);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        } else if (mode === 'create') {
            rqItemCreate.mutate(
                { content: values },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            notifyModification('create', data.id);
                            //после создания переход на редактирование
                            navigate(`/admin/articles/${data.id}/edit`);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        }
    };

    //поменять значение содержание обложки при переключении switch
    React.useEffect(() => {
        if (watchIsVideoCover) setValue('cover', '', { shouldDirty: true });
        else setValue('coverVideo', [], { shouldDirty: true });
    }, [watchIsVideoCover, setValue]);

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = async () => {
        const v = await trigger();
        if (!v) return;
        handleSubmit(onSubmit)();
    };

    //*************************************************************************************************************
    //Опубликовать
    const handlePublish = async () => {
        const v = await trigger();
        if (!v) return;
        setValue('isPublished', true, { shouldDirty: true });
        handleSubmit(onSubmit)();
    };

    //*************************************************************************************************************
    //Снять с публикации
    const handleUnpublish = async () => {
        //здесь нет проверки свойств, т.к. при снятии с публикации другие свойства не сохраняются
        setValue('isPublished', false, { shouldDirty: true });
        handleSubmit(onSubmit)();
    };

    const isLoading = rqItemUpdate.isLoading || rqItemCreate.isLoading;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <GridRow sx={{ mt: 0 }}>
                <GridCol>
                    {/* обложка - картинка */}
                    {!watchIsVideoCover && (
                        <FormDropzone
                            control={control}
                            name="cover"
                            invalidText={errors.cover?.message}
                            label="Обложка"
                            placeholder="Загрузите обложку здесь"
                        />
                    )}
                    {/* видео обложка */}
                    {watchIsVideoCover && (
                        <FormHTMLEditor
                            control={control}
                            name="coverVideo"
                            label="Видео обложка"
                            invalidText={(errors.coverVideo as unknown as FieldError)?.message}
                            placeholder="Это видео будет показано в ленте"
                            blockTypes={allowBlockTypes}
                        />
                    )}
                </GridCol>
            </GridRow>

            {/* переключатель обложки */}
            <GridRow sx={{ mt: 0 }}>
                <GridCol>
                    <FormSwitch control={control} name="isVideoCover" label="Видео обложка" />
                </GridCol>
            </GridRow>

            {/* Название */}
            <GridRow>
                <GridCol md={8}>
                    <FormTextInput
                        control={control}
                        name="title"
                        invalidText={errors.title?.message}
                        label="Название"
                        placeholder="Введите название статьи"
                    />
                </GridCol>
            </GridRow>

            {/* Подзаголовок */}
            <GridRow>
                <GridCol md={12}>
                    <FormTextInput
                        control={control}
                        name="annotate"
                        invalidText={errors.annotate?.message}
                        label="Подзаголовок"
                        placeholder="Кратко опишите о чем статья"
                        multiline={true}
                    />
                </GridCol>
            </GridRow>

            {/* Дата публикации */}
            <GridRow>
                <GridCol md={5}>
                    <FormDateTime
                        control={control}
                        name="publishedAt"
                        invalidText={errors.publishedAt?.message}
                        label="Дата публикации"
                        // placeholder="Введите дату"
                    />
                </GridCol>
            </GridRow>

            {/* Список тегов */}
            <GridRow>
                <GridCol xs={7}>
                    <FormMultiSelect
                        control={control}
                        name="tags"
                        label="Теги"
                        api={tagsApi.getMultiSelect}
                        invalidText={(errors.tags as unknown as FieldError)?.message}
                        tagsLimit={10}
                        optionsLimit={50}
                    />
                </GridCol>
            </GridRow>

            {/* Автор */}
            <GridRow>
                <GridCol xs={4}>
                    <FormSingleSelect
                        control={control}
                        name="author"
                        label="Автор"
                        api={usersApi.getSingleSelect}
                        invalidText={(errors.author?.id as unknown as FieldError)?.message}
                        optionsLimit={50}
                        optionLabel="commonName"
                    />
                </GridCol>
            </GridRow>

            {/* Содержание */}
            <GridRow>
                <GridCol xs={12}>
                    <FormHTMLEditor
                        control={control}
                        name="editorData"
                        label="Контент"
                        invalidText={(errors.editorData as unknown as FieldError)?.message}
                        forceRefreshToken={forceRefreshToken.current}
                    />
                </GridCol>
            </GridRow>

            <Box sx={{ mt: 10 }}>
                <SaveAndPublishButtons
                    isPublished={getValues('isPublished') || false}
                    isDirty={isDirty}
                    pathToList="/admin/articles"
                    onSave={handleSave}
                    onPublish={handlePublish}
                    onUnpublish={handleUnpublish}
                    isLoading={isLoading}
                />
            </Box>
        </form>
    );
};

export default ItemForm;
