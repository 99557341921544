import { AxiosResponse } from 'axios';
import { IGetSelectParams } from 'api';

//управление ячейкой таблицы
//этот тип будет передан из списка в функцию format ячейки
/* eslint-disable @typescript-eslint/no-explicit-any */
export type TListControl = {
    value: any;
    row: any;
    disabled: boolean;
    blockListFunc: (v: boolean) => void;
    refreshListFunc: () => void;
};

export type ColumnDescription = {
    dataField: string;
    columnTitle: string;
    width?: string;
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
    textOverflow?: boolean;
    format?: (v: TListControl) => string | JSX.Element;
    disableSort?: boolean;
};

export type TAction = { name: string; handle: () => void };

//типы данных для хранения фильтров в UI
export type TFilterValue = string | Array<{ id: number }>;
export type TFilterValues = Array<{ key: string; value: TFilterValue }>;
//типы данных для отправки на сервер
type TSelectValue = string | string[];
export type TSelectValues = Record<string, TSelectValue>;

export enum TFilterType {
    TEXT = 'TEXT',
    MULTI_SELECT = 'MULTI_SELECT',
}

export type FilterDescription =
    | {
          type: TFilterType.TEXT;
          label: string;
          key: string;
          defaultValue: TFilterValue;
      }
    | {
          type: TFilterType.MULTI_SELECT;
          label: string;
          key: string;
          defaultValue: TFilterValue;
          api: (params: IGetSelectParams) => Promise<AxiosResponse<any, any>>;
      };

export interface TDefaultSelectParams {
    page: number;
    rowsPerPage: number;
    sortField?: string;
    sortOrder?: 'asc' | 'desc';
}

export interface ISelectParams {
    page: number;
    sizePerPage: number;
    sortField?: string;
    sortOrder?: 'asc' | 'desc';
    filters?: TSelectValues;
}
