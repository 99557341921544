import React from 'react';
import { Typography, Stack } from '@mui/material';

interface IProps {
    error?: Error;
}

//*************************************************************************************************************
//Сообщение об ошибке
const ErrorWarning: React.FC<IProps> = ({ error }) => {
    return (
        <Stack direction="column" justifyContent="flex-start" alignItems="center">
            <Typography variant="h3" align="left" sx={{ color: 'error.main', mt: 3, ml: 3 }}>
                Ошибка обработки данных!
            </Typography>
            {error && (
                <Typography variant="h3" align="left" sx={{ color: 'error.main', mt: 3, ml: 3 }}>
                    {error.message}
                </Typography>
            )}
        </Stack>
    );
};

export default ErrorWarning;
