import React from 'react';
import { useNavigate } from 'react-router-dom';

import { articlesApi, tagsApi } from 'api';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { DataTable } from 'components/table';
import { ColumnDescription, TAction, TFilterType, FilterDescription } from 'components/table/types';
import { ItemActionButtons } from 'components/table/ItemActionButtons';
import { useAppStateContext } from 'AppContext';

export const ArticleList = () => {
    const navigate = useNavigate();
    //данные из контекста
    const { permissions } = useAppStateContext();
    const userAccess = permissions && permissions.articles;

    const columnsDescription: ColumnDescription[] = [
        {
            disableSort: true,
            dataField: '#table-actions',
            columnTitle: 'Действия',
            width: '7em',
            align: 'center',
            format: (listControl) => (
                <ItemActionButtons
                    listControl={listControl}
                    apiDeleteOne={articlesApi.deleteOne}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
        },
        {
            dataField: 'id',
            columnTitle: 'ID',
            width: '7em',
        },
        {
            dataField: 'title',
            columnTitle: 'Название',
            width: '45em',
            textOverflow: true,
        },
        {
            dataField: 'isPublished',
            columnTitle: 'Опубликовано',
            width: '4em',
            format: ({ value }) => (value ? 'Да' : 'Нет'),
        },
        {
            dataField: 'publishedAt',
            columnTitle: 'Дата публикации',
            width: '12em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
        {
            dataField: 'isPublishedPortal',
            columnTitle: 'Портал',
            width: '4em',
            format: ({ value }) => (value ? 'Да' : 'Нет'),
        },
        {
            dataField: 'isPublishedTelegram',
            columnTitle: 'Телеграм',
            width: '4em',
            format: ({ value }) => (value ? 'Да' : 'Нет'),
        },
        {
            dataField: 'importantTo',
            columnTitle: 'Главная до',
            width: '12em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
        {
            dataField: 'createdAt',
            columnTitle: 'Дата создания',
            width: '12em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
    ];

    const filtersDescription: FilterDescription[] = [
        {
            type: TFilterType.TEXT,
            label: 'поиск по шаблону',
            key: 'textFilter',
            defaultValue: '',
        },
        {
            type: TFilterType.MULTI_SELECT,
            label: 'поиск по тегам',
            key: 'tagsFilter',
            defaultValue: [],
            api: tagsApi.getMultiSelect,
        },
    ];

    //Добавить действия
    const actions: TAction[] = [];
    if (userAccess?.create === true)
        actions.push({
            name: 'Добавить статью',
            handle: () => navigate(`/admin/articles/create`),
        });

    return (
        <DataTable
            listStorageName="articles"
            title="Список публикаций"
            columnsDescription={columnsDescription}
            filtersDescription={filtersDescription}
            api={articlesApi.getList}
            actions={actions}
            defaultSelectParams={{
                page: 0,
                rowsPerPage: 20,
                sortField: 'createdAt',
                sortOrder: 'desc',
            }}
        />
    );
};
