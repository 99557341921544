import React from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { FormControl, InputLabel, FormHelperText, Select, MenuItem } from '@mui/material';

type TOptionsList = Array<{
    id: number;
    title: string;
}>;

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    invalidText?: string;
    validText?: string;
    options: TOptionsList;
    fullWidth?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium';
}

export const FormSelect = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label = '',
    invalidText,
    validText = ' ',
    options,
    fullWidth = false,
    disabled = false,
    size = 'medium',
}: Props<T>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });

    const isError = !!invalidText;

    return (
        <FormControl variant="outlined" fullWidth={fullWidth} error={isError} disabled={disabled} size={size}>
            <InputLabel htmlFor={name}>{label}</InputLabel>

            <Select value={value} label={label} onChange={onChange}>
                {options.map((item) => (
                    <MenuItem key={`${name}-${item.id}-select-key`} value={item.id}>
                        {item.title}
                    </MenuItem>
                ))}
            </Select>

            <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }} error={isError}>
                {isError ? invalidText : validText}
            </FormHelperText>
        </FormControl>
    );
};
