import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { getDirtyValues } from 'lib/getEditedValues';
import { useIsMounted } from 'lib/hooks';
import { FormCheckBox } from 'components/inputs/FormCheckBox';
import { FormTextInput } from 'components/inputs/FormTextInput';
import SaveButtons from 'components/buttons/SaveButtons';
import { GridRow, GridCol } from 'components/styled/grid';
import { useNotification } from 'components/notify';
import { IItem, IItemContent, useRqItemUpdate, useRqItemCreate, useRqSectionListRead } from '../queries';

interface IProps {
    mode: 'edit' | 'create';
    id?: number;
    content?: Partial<IItemContent>;
}

const validationSchema = yup.object({
    title: yup.string().max(256, `максимум 256 символов`).required(`поле не заполнено`),
});

const ItemForm: React.FC<IProps> = ({ id, content, mode }) => {
    const { notifyModification, notifyApiError } = useNotification();
    const navigate = useNavigate();
    //кеш списка секций
    const rqSectionList = useRqSectionListRead();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    const rqItemCreate = useRqItemCreate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные
    let defaultValues: Partial<IItemContent> = {};
    if (mode === 'create') {
        defaultValues = {
            title: '',
        };
    } else {
        const { id: itemId, ...rest } = content as IItem;
        defaultValues = { ...rest };
    }
    //типы доступа
    const accessTypes = [
        { type: 'read', title: 'Чтение' },
        { type: 'update', title: 'Изменение' },
        { type: 'create', title: 'Создание' },
        { type: 'delete', title: 'Удаление' },
    ];

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
    } = useForm<IItemContent>({ defaultValues, resolver: yupResolver(validationSchema) });

    const onSubmit = (values: IItemContent) => {
        if (mode === 'edit' && id) {
            rqItemUpdate.mutate(
                { id, content: getDirtyValues(dirtyFields, values) as Partial<IItemContent> },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            notifyModification('update', id);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        } else if (mode === 'create') {
            rqItemCreate.mutate(
                { content: values },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            notifyModification('create', data.id);
                            //после создания переход на редактирование
                            navigate(`/admin/role/${data.id}/edit`);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        }
    };

    const isLoading = rqItemUpdate.isLoading || rqItemCreate.isLoading || rqSectionList.isLoading;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <GridRow>
                <GridCol md={8}>
                    {/* Название роли */}
                    <FormTextInput
                        control={control}
                        name="title"
                        invalidText={errors.title?.message}
                        label="Название роли"
                    />
                </GridCol>
            </GridRow>

            {mode === 'edit' && rqSectionList.isSuccess && (
                <React.Fragment>
                    {/* Заголовок списка доступа */}
                    <GridRow sx={{ pb: 1, mb: 1, borderBottom: 1, borderWidth: '1px', borderColor: 'grey.300' }}>
                        <GridCol xs={4} />
                        {accessTypes.map((accesType) => (
                            <GridCol key={`accesType-${accesType.type}`} xs={2}>
                                <Typography variant="body1" align="center">
                                    {accesType.title}
                                </Typography>
                            </GridCol>
                        ))}
                    </GridRow>

                    {/* Список доступа */}
                    {rqSectionList.data.map((section) => (
                        <GridRow key={`section-${section.id}`} sx={{ my: 0 }}>
                            {/* Название доступа */}
                            <GridCol xs={4}>
                                <Typography variant="body1" align="left">
                                    {section.title}
                                </Typography>
                            </GridCol>

                            {/* Состав доступа */}
                            {accessTypes.map((accesType) => (
                                <GridCol key={`accesType-${accesType.type}`} xs={2} sx={{ alignItems: 'center' }}>
                                    <FormCheckBox
                                        control={control}
                                        name={`permissions.${section.name}.${accesType.type}`}
                                        rootStyle={{ padding: 0, mb: 1 }} //убрать отступы в контроле Checkbox
                                    />
                                </GridCol>
                            ))}
                        </GridRow>
                    ))}
                </React.Fragment>
            )}

            <Box sx={{ mt: 10 }}>
                <SaveButtons
                    buttonType="submit"
                    isDirty={isDirty}
                    pathToList="/admin/role"
                    disabled={isLoading}
                    isLoading={isLoading}
                />
            </Box>
        </form>
    );
};

export default ItemForm;
