import React from 'react';
import { useForm, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { roleApi } from 'api';
import { usePublicConfig } from 'lib/config';
import { getDirtyValues } from 'lib/getEditedValues';
import { useIsMounted } from 'lib/hooks';
import { checkString, checkPassword, checkNumber } from 'lib/yup';
import { FormTextInput } from 'components/inputs/FormTextInput';
import { FormSelect } from 'components/inputs/FormSelect';
import { FormSingleSelect } from 'components/inputs/FormSingleSelect';
import { FormPassword } from 'components/inputs/FormPassword';
import SaveButtons from 'components/buttons/SaveButtons';
import { GridRow, GridCol } from 'components/styled/grid';
import { useNotification } from 'components/notify';
import { IItem, IItemContent, useRqItemUpdate, useRqItemCreate, UserStatus, userStatusList } from '../queries';

interface IProps {
    mode: 'edit' | 'create';
    id?: number;
    content?: Partial<IItemContent>;
}

const ItemForm: React.FC<IProps> = ({ id, content, mode }) => {
    const { notifyModification, notifyApiError } = useNotification();
    const { ready: configReady, publicConfig } = usePublicConfig();
    const navigate = useNavigate();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    const rqItemCreate = useRqItemCreate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //показать/скрыть пароль
    const [editPassword, setEditPassword] = React.useState(false);

    //данные
    let defaultValues: Partial<IItemContent> = {};
    if (mode === 'create') {
        defaultValues = {
            email: '',
            status: UserStatus.ACTIVE,
            commonName: '',
            firstname: '...',
            lastname: '...',
            password: '',
            passwordCopy: '',
            role: { id: 0 },
        };
    } else {
        const { id: itemId, ...rest } = content as IItem;
        defaultValues = { ...rest };
    }

    //сформировать 2 схемы контроля - отдельно для редактирования пароля
    //перенесено "внутрь" функции из-за доступа к publicConfig hook
    const schema1 = {
        email: checkString({ maxLength: 128, minLength: 2 }),
        commonName: checkString({ maxLength: 64, minLength: 2 }),
        role: yup.object({ id: checkNumber({}) }),
    };
    const schema2 = {
        ...schema1,
        ...{
            password: checkPassword({
                maxLength: publicConfig.passwordMaxLength,
                minLength: publicConfig.passwordMinLength,
                regExp: publicConfig.passwordPattern as RegExp,
            }),
            passwordCopy: checkPassword({
                maxLength: publicConfig.passwordMaxLength,
                minLength: publicConfig.passwordMinLength,
            }),
        },
    };
    const validationSchema1 = yup.object(schema1);
    const validationSchema2 = yup.object(schema2).test('myCustomTest', '', (obj) => {
        if ((obj.password || obj.passwordCopy) && obj.password !== obj.passwordCopy)
            return new yup.ValidationError('Пароли должны совпадать!', null, 'password');
        return true;
    });

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
        resetField,
    } = useForm<IItemContent>({
        defaultValues,
        context: editPassword, //в контекст для resolver передаем признак редактирования пароля
        resolver: (data, context, options) => {
            //в зависимости от признака редактирования пароля задействовать нужную схему контроля
            const schema = context ? validationSchema2 : validationSchema1;
            return yupResolver(schema)(data, context, options);
        },
    });

    const onSubmit = (values: IItemContent) => {
        if (mode === 'edit' && id) {
            rqItemUpdate.mutate(
                { id, content: getDirtyValues(dirtyFields, values) as Partial<IItemContent> },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            notifyModification('update', id);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        } else if (mode === 'create') {
            rqItemCreate.mutate(
                { content: values },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            notifyModification('create', data.id);
                            //после создания переход на редактирование
                            navigate(`/admin/users/${data.id}/edit`);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        }
    };

    const isLoading = rqItemUpdate.isLoading || rqItemCreate.isLoading;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* email */}
            <GridRow>
                <GridCol md={8}>
                    <FormTextInput control={control} name="email" invalidText={errors.email?.message} label="Email" />
                </GridCol>
            </GridRow>

            {/* Псевдоним */}
            <GridRow>
                <GridCol md={5}>
                    <FormTextInput
                        control={control}
                        name="commonName"
                        invalidText={errors.commonName?.message}
                        label="Псевдоним"
                    />
                </GridCol>
            </GridRow>

            {/* показать/скрыть пароль */}
            <Button
                variant="outlined"
                size="small"
                color="primary"
                type="button"
                onClick={() =>
                    setEditPassword((prev) => {
                        //если пароль скрываем, то сброс состояния контролов ввода пароля
                        if (prev) {
                            resetField('password');
                            resetField('passwordCopy');
                        }
                        return !prev;
                    })
                }
                sx={{ my: 2 }}
            >
                {editPassword ? 'Скрыть пароль' : 'Показать пароль'}
            </Button>

            {/* Пароль */}
            {editPassword && (
                <GridRow columnSpacing={2}>
                    <GridCol md={5}>
                        <FormPassword
                            control={control}
                            name="password"
                            invalidText={errors.password?.message}
                            label="Пароль"
                            disabled={!configReady}
                        />
                    </GridCol>
                    <GridCol md={5}>
                        <FormPassword
                            control={control}
                            name="passwordCopy"
                            invalidText={errors.passwordCopy?.message}
                            label="Подтверждение пароля"
                            disabled={!configReady}
                        />
                    </GridCol>
                </GridRow>
            )}

            <GridRow columnSpacing={2}>
                {/* Статус */}
                <GridCol md={4}>
                    <FormSelect
                        control={control}
                        name="status"
                        label="Статус"
                        invalidText={errors.status?.message}
                        options={userStatusList}
                        disabled={mode === 'edit' && id === 1}
                    />
                </GridCol>

                {/* Роль */}
                <GridCol md={4}>
                    <FormSingleSelect
                        control={control}
                        name="role"
                        label="Роль"
                        api={roleApi.getSelect}
                        invalidText={(errors.role?.id as unknown as FieldError)?.message}
                        optionsLimit={50}
                        //optionLabel="title"
                    />
                </GridCol>
            </GridRow>

            <Box sx={{ mt: 10 }}>
                <SaveButtons
                    buttonType="submit"
                    isDirty={isDirty}
                    pathToList="/admin/users"
                    disabled={isLoading}
                    isLoading={isLoading}
                />
            </Box>
        </form>
    );
};

export default ItemForm;
