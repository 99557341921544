/* ЗАМЕНЕНО на RegExp - Удаляет из конца строки символы, входящие в строку charsToDelete */
/*
export const trimEndCharacters = (source: string, charsToDelete: string) => {
    let i = source.length - 1;
    while (i >= 0) {
        if (charsToDelete.includes(source[i])) i--;
        else break;
    }
    return i >= 0 ? source.slice(0, i + 1) : '';
};
*/

//*************************************************************************************************************
//Дополнить строку слева нулями до count символов.
export function padLeft(value: number, count: number) {
    let strValue;
    if (typeof value === 'string') strValue = value;
    else strValue = value.toString();
    strValue = '0'.repeat(count) + strValue;
    return strValue.substring(strValue.length - count);
}

//*************************************************************************************************************
//Заменить все вхождения шаблона stringToSearch в строке stringToModify на шаблон stringToReplace
export function replaceAll(stringToModify: string, stringToSearch: string, stringToReplace: string) {
    return stringToModify.split(stringToSearch).join(stringToReplace);
}

//****************************************************************************************
//Разбить строку по символам перевода строки и сформировать новую с окружением подстрок символами <p>...</p>
export function multilineTextToHtml(text: string) {
    const a = text.split('\n');
    let result = '';
    for (let i = 0; i < a.length; i++) {
        result = `${result}<p>${a[i]}</p>`;
    }
    return result;
}

//****************************************************************************************
//Удалить из html текста определенные теги и вернуть длину результирующей строки
export const cleanedHtmlLength = (s: string) => {
    /* ЗАМЕНЕНО на RegExp
    const tags = [
        '<b>',
        '</b>',
        '</a>',
        '<strong>',
        '</strong>',
        '<i>',
        '</i>',
        '<em>',
        '</em>',
        '<u>',
        '</u>',
        '<ins>',
        '</ins>',
        '<s>',
        '</s>',
        '<strike>',
        '</strike>',
        '<del>',
        '</del>',
        '<span class="tg-spoiler">',
        '</span>',
        '<tg-spoiler>',
        '</tg-spoiler>',
        '<code>',
        '</code>',
        '<pre>',
        '</pre>',
    ];
    let i = 0;
    let len = s.length;
    while (i < s.length) {
        //обработать ссылки, типа
        //'<a href="http://www.example.com/">inline URL</a>';
        if (s.startsWith('<a href=', i)) {
            const pos = s.indexOf('>', i + 8);
            if (pos !== -1) {
                len = len - (pos - i + 1);
                i = i + (pos - i);
            }
        }
        //обработать остальные теги
        else {
            for (let j = 0; j < tags.length; j++) {
                const tag = tags[j];
                if (s.startsWith(tag, i)) {
                    len = len - tag.length;
                    i = i + tag.length - 1;
                    break;
                }
            }
        }
        i++;
    }
    */
    const pattern = /<\/?(a|b|strong|i|em|u|ins|s|strike|del|span|tg-spoiler|code|pre)(\s+?.+?)*?>/giu;
    return s.replace(pattern, '').length;
};
