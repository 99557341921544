import { createTheme, ThemeOptions } from '@mui/material/styles';
import palette from './palette';
import fontInter from './typography';

export const theme: ThemeOptions = createTheme({
    typography: {
        //шрифт Inter загружен из assets в модуле typography.tsx и подключен через MuiCssBaseline / styleOverrides / fontInter
        //здесь определяем default семейство шрифтов для всего приложения
        fontFamily: 'Inter',
    },
    palette,
    components: {
        //пример задания default стиля для отдельного компонента
        //MuiCircularProgress - имя компонента
        //styleOverrides - изменяем стили
        //circle - имя правила из колонки componentApi / CSS / Rule name
        //color - свойство css
        //либо можно в конкретном компоненте задать имя стиля из колонки componentApi / CSS / Global class
        //<CircularProgress sx={{ '& .MuiCircularProgress-circle': { color: 'primary.main' } }} />
        // MuiCircularProgress: {
        //     styleOverrides: {
        //         circle: { color: palette.primary.main },
        //     },
        // },

        // MuiListItemButton: {
        //     styleOverrides: {
        //         root: {
        //             '&.Mui-selected': {
        //                 color: 'red',
        //             },
        //         },
        //     },
        // },

        //темный фон для кнопок
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme: th }) => ({
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                            backgroundColor: th.palette.primary.dark,
                        }),
                }),
            },
        },

        //тень для карточек
        MuiCard: {
            styleOverrides: {
                root: { boxShadow: '0 0 2rem 0 rgba(136,152,170,.15)' },
            },
        },

        //default стили для всего приложения
        MuiCssBaseline: {
            styleOverrides: {
                fontInter,
            },
        },
    },
});
