import React from 'react';
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';
import { CardContent, Typography, Button, CircularProgress } from '@mui/material';

import { ViewHeader } from 'components/headers/ViewHeader';
import { StyledCard } from 'components/styled/card';
import { GridRow, GridCol } from 'components/styled/grid';
import { useIsMounted } from 'lib/hooks';
import { useNotification } from 'components/notify';
import { transferApi } from 'api';

const Transfer: React.FC = () => {
    const { notifyApiError, notifySuccess } = useNotification();
    const [file, setFile] = React.useState<File | null>();
    const [accepted, setAccepted] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();

    const handleSend = () => {
        if (!file) return;
        setIsLoading(true);
        transferApi
            .createTransferFile(file)
            .then((/*res*/) => {
                setAccepted(true);
                notifySuccess('Файл получен');
            })
            .catch((error) => {
                setAccepted(false);
                notifyApiError(error);
            })
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
    };

    const handleImport = () => {
        if (!file) return;
        setIsLoading(true);
        transferApi
            .import()
            .then((res) => {
                notifySuccess(res.data.result || 'Импорт выполнен');
            })
            .catch((error) => {
                notifyApiError(error);
            })
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <ViewHeader title="Импорт данных" icon={<FileDownloadIcon />} />
            <StyledCard>
                <CardContent>
                    <GridRow>
                        <GridCol md={8} sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
                            <label htmlFor="upload-data">
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-data"
                                    name="upload-data"
                                    type="file"
                                    accept="text/csv"
                                    onChange={(event) => {
                                        const v = (event.target as HTMLInputElement).files?.item(0);
                                        setFile(v);
                                        setAccepted(false);
                                    }}
                                />
                                <Button variant="contained" component="span" sx={{ whiteSpace: 'nowrap' }}>
                                    Выбрать CSV файл
                                </Button>
                            </label>
                            <Typography variant="body1" align="left" sx={{ ml: 3 }}>
                                {file?.name || 'файл не выбран'}
                            </Typography>
                            {isLoading && <CircularProgress size="1.5rem" sx={{ color: 'green', ml: 3 }} />}
                        </GridCol>
                    </GridRow>

                    <GridRow>
                        <GridCol md={8} sx={{ alignItems: 'flex-start' }}>
                            <Button
                                variant="contained"
                                component="span"
                                onClick={handleSend}
                                disabled={!file}
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                Передать на сервер
                            </Button>
                        </GridCol>
                    </GridRow>

                    <GridRow>
                        <GridCol md={8} sx={{ alignItems: 'flex-start' }}>
                            <Button
                                variant="contained"
                                component="span"
                                onClick={handleImport}
                                disabled={!file || !accepted}
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                Выполнить импорт
                            </Button>
                        </GridCol>
                    </GridRow>
                </CardContent>
            </StyledCard>
        </React.Fragment>
    );
};

export default Transfer;
