import { useCallback } from 'react';
import { useNotifyActionsContext } from 'components/notify';
import { AxiosError } from 'axios';

type TAction = 'create' | 'update' | 'delete';

const prepareError = (err: AxiosError): string => {
    if (err.response?.data.message) return err.response.data.message;
    if (err.response?.statusText) return `${err.message}: ${err.response.statusText}`;

    return err.message ? err.message : 'Ошибка сервера';
};

export const useNotification = () => {
    //действия из контекста
    const { ctxSetMessage } = useNotifyActionsContext();

    const notifyModification = useCallback(
        (action: TAction, id: number) => {
            let msg = '';
            if (action === 'create') msg = 'добавлена';
            else if (action === 'update') msg = 'обновлена';
            else msg = 'удалена';

            ctxSetMessage(`Запись #${id} ${msg}`, 'success');
        },
        [ctxSetMessage]
    );

    const notifySuccess = useCallback((message: string) => ctxSetMessage(message, 'success'), [ctxSetMessage]);

    const notifyError = useCallback((message: string) => ctxSetMessage(message, 'error'), [ctxSetMessage]);

    const notifyApiError = useCallback(
        (err: unknown) => ctxSetMessage(prepareError(err as AxiosError), 'error'),
        [ctxSetMessage]
    );

    const notifyServerError = useCallback(() => ctxSetMessage('Ошибка сервера', 'error'), [ctxSetMessage]);

    return { notifySuccess, notifyError, notifyServerError, notifyModification, notifyApiError };
};
