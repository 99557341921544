import React from 'react';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { useAppStateContext, useAppActionsContext } from 'AppContext';
import { authApi } from 'api';
import { useNotification } from 'components/notify';
import { auth } from 'lib/auth';
import AuthLayout from 'layouts/auth/AuthLayout';
import { AdminLayout } from 'layouts/Admin';

const App: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { notifyApiError } = useNotification();
    //данные из контекста
    const { id: userId } = useAppStateContext();
    //действия из контекста
    const { ctxSetUserData, ctxClearUserData } = useAppActionsContext();

    //для обработки refresh браузера
    //если user не залогинен и в сессии сохранены токены, то делаем попытку регистрации по токену
    //auth layout не будет отображаться, пока установлен признак auth.inLoginProcess
    React.useEffect(() => {
        const { pathname: startPathName } = location;
        if (!userId && auth.accessToken) {
            auth.inLoginProcess = true;
            authApi
                .loginByToken()
                .then((res) => {
                    const { username, firstname, lastname, permissions, id, commonName } = res.data;
                    ctxSetUserData({ id, username, firstname, lastname, permissions, commonName });
                    navigate(startPathName, { replace: true });
                })
                .catch((error) => {
                    auth.clear();
                    ctxClearUserData();
                    notifyApiError(error);
                    navigate('/auth', { replace: true });
                })
                .finally(() => {
                    auth.inLoginProcess = false;
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes>
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="/admin/*" element={<AdminLayout />} />
            <Route path="*" element={<Navigate to="/auth" replace={true} />} />
        </Routes>
    );
};

export default App;
