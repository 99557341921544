import { useQuery, useMutation, useQueryClient } from 'react-query';

import { articlesApi as itemApi } from 'api';
import { TEditorBlockData } from 'components/edit/HTMLEditor/types';

export interface IItemContent {
    cover: string;
    coverVideo: TEditorBlockData[];
    title: string;
    annotate: string;
    editorData: TEditorBlockData[];
    tags: Array<{ title: string; id: number }>;
    author: { id: number };
    isVideoCover: boolean;
    publishedAt: Date;
    isPublished: boolean;
}

export interface IItem extends Partial<IItemContent> {
    id: number;
}

//ключ кеша
const queryKey = 'articlesContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    const response = await itemApi.getOne(id);
    //статья имеет сложную структуру - mobile/portal/telegram
    return {
        id: response.data.id,
        ...response.data.mobile,
    };
}
export function useRqItemRead(id: number) {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(id),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    const response = await itemApi.updateOne(id, {
        dataType: 'MOBILE',
        mobile: {
            ...content,
            ...(content.tags && { tags: content.tags.map((tag) => tag.id) }), //требует массив id
            ...(content.author && { author: content.author.id }), //требует одиночный id
        },
    });
    return { id: response.data.id, ...response.data.mobile };
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        //onSuccess: (/*data*/) => queryClient.invalidateQueries([queryKey]),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};

//*************************************************************************************************************
//добавить
type TCreateProps = { content: Partial<IItemContent> };
async function itemCreate({ content }: TCreateProps): Promise<IItem> {
    const response = await itemApi.createOne({
        dataType: 'MOBILE',
        mobile: {
            ...content,
            ...(content.tags && { tags: content.tags.map((tag) => tag.id) }), //требует массив id
            ...(content.author && { author: content.author.id }), //требует одиночный id
        },
    });
    return { id: response.data.id, ...response.data.mobile };
}
export const useRqItemCreate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TCreateProps) => itemCreate(props),
        //onSuccess: (/*data*/) => queryClient.invalidateQueries([queryKey]),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
