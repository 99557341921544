import React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { v4 as uuid } from 'uuid';

interface IProps {
    content: JSX.Element;
}

export const Background: React.FC<IProps> = ({ content }) => {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                px: { md: 4, xs: 2 },
                py: 2,
            }}
        >
            <Fade key={uuid()} timeout={500} in={true}>
                <div>{content}</div>
            </Fade>
        </Box>
    );
};
