import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { getDirtyValues } from 'lib/getEditedValues';
import { useIsMounted } from 'lib/hooks';
import { FormTextInput } from 'components/inputs/FormTextInput';
import SaveButtons from 'components/buttons/SaveButtons';
import { GridRow, GridCol } from 'components/styled/grid';
import { useNotification } from 'components/notify';
import { IItem, IItemContent, useRqItemUpdate, useRqItemCreate } from '../queries';

interface IProps {
    mode: 'edit' | 'create';
    id?: number;
    content?: Partial<IItemContent>;
}

const validationSchema = yup.object({
    title: yup.string().max(256, `максимум 256 символов`).required(`поле не заполнено`),
});

const ItemForm: React.FC<IProps> = ({ id, content, mode }) => {
    const { notifyModification, notifyApiError } = useNotification();
    const navigate = useNavigate();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    const rqItemCreate = useRqItemCreate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные
    let defaultValues: Partial<IItemContent> = {};
    if (mode === 'create') {
        defaultValues = {
            title: '',
        };
    } else {
        const { id: itemId, ...rest } = content as IItem;
        defaultValues = { ...rest };
    }

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
    } = useForm<IItemContent>({ defaultValues, resolver: yupResolver(validationSchema) });

    const onSubmit = (values: IItemContent) => {
        if (mode === 'edit' && id) {
            rqItemUpdate.mutate(
                { id, content: getDirtyValues(dirtyFields, values) as Partial<IItemContent> },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            notifyModification('update', id);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        } else if (mode === 'create') {
            rqItemCreate.mutate(
                { content: values },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            notifyModification('create', data.id);
                            //после создания переход на редактирование
                            navigate(`/admin/tags/${data.id}/edit`);
                        }
                    },
                    onError: (error) => {
                        notifyApiError(error);
                    },
                }
            );
        }
    };

    const isLoading = rqItemUpdate.isLoading || rqItemCreate.isLoading;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <GridRow>
                <GridCol md={8}>
                    {/* Название тега */}
                    <FormTextInput
                        control={control}
                        name="title"
                        invalidText={errors.title?.message}
                        label="Название тега"
                    />
                </GridCol>
            </GridRow>

            <Box sx={{ mt: 10 }}>
                <SaveButtons
                    buttonType="submit"
                    isDirty={isDirty}
                    pathToList="/admin/tags"
                    disabled={isLoading}
                    isLoading={isLoading}
                />
            </Box>
        </form>
    );
};

export default ItemForm;
