import React from 'react';
import { Typography, Stack, Button, IconButton } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';

import { TAction } from './types';

interface IProps {
    title: string;
    actions?: TAction[];
    refreshListFunc: () => void;
}

export const ListHeader: React.FC<IProps> = ({ title, actions, refreshListFunc }) => {
    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ my: 2 }}>
            <Typography variant="h6" align="left" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                {title}
            </Typography>
            {actions &&
                actions.length > 0 &&
                actions.map((action, index) => (
                    <Button
                        key={action.name}
                        onClick={action.handle}
                        variant="contained"
                        size="small"
                        sx={{ ...(index > 0 && { ml: 2 }) }}
                    >
                        {action.name}
                    </Button>
                ))}
            <IconButton onClick={refreshListFunc} size="large" sx={{ ml: 2, p: 0 }}>
                <RefreshIcon fontSize="inherit" />
            </IconButton>
        </Stack>
    );
};
