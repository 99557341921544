import axios, { AxiosResponse, Method, AxiosPromise } from 'axios';
import { stringify } from 'qs';

import { auth } from 'lib/auth';
import { ISelectParams } from 'components/table/types';

//пока не надо возвращать куки на сервер
//export const axiosInstance = axios.create({ withCredentials: true });
export const axiosInstance = axios.create();

//*************************************************************************************************************
//Во всех исходящих запросах в заголовок записывается access токен / refresh токен
axiosInstance.interceptors.request.use(
    (config) => {
        const v = config;
        let token = '';

        if (
            auth.factor2Token &&
            (v.url?.includes('auth/login-by-factor2-code') || v.url?.includes('auth/repeat-factor2-code'))
        ) {
            token = auth.factor2Token;
        } else if (v.url?.includes('auth/refresh')) {
            token = auth.refreshToken || '';
        } else {
            token = auth.accessToken || '';
        }

        if (token) {
            if (!v.headers) v.headers = {};
            v.headers.Authorization = `Bearer ${token}`;
        }
        return v;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//*************************************************************************************************************
//Перехватить ошибку 401 (не авторизован) в ответе на запрос
//и обновить пару токенов.

// переменная для хранения запроса refresh токена
let refreshTokenRequest: AxiosPromise | null = null;

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const { config } = error.response;
        //если код ответа 401 (не авторизован) и это не повторный запрос (например, с новым access токеном) и не запрос на login
        //то запросить новую пару токенов и затем повторить исходный запрос, но с новым access токеном
        if (error.response?.status === 401 && !config.__retry__ && !config.url?.includes('auth/login')) {
            //__retry__ - признак повторного запроса. Размещается в config и потом axios его будет возвращать
            config.__retry__ = true;

            //обновить пару токенов
            let accessToken;
            let refreshToken;
            try {
                //запрос выполняется только, если его еще не было сделано
                if (refreshTokenRequest === null) {
                    const configRefresh = {
                        method: 'get' as Method,
                        url: '/auth/refresh',
                        __retry__: true,
                    };
                    //здесь без await - только промис запоминаем
                    refreshTokenRequest = axiosInstance(configRefresh);
                }
                //здесь ждем разрешения промиса, т.е. все параллельные исходные запросы будут ждать результат
                const result = await refreshTokenRequest;
                accessToken = result.data.accessToken;
                refreshToken = result.data.refreshToken;
            } catch (e) {
                accessToken = null;
                refreshToken = null;
            } finally {
                refreshTokenRequest = null;
            }

            //при ошибке
            if (!accessToken || !refreshToken) {
                const tokenError = new Error('empty token');
                return Promise.reject(tokenError);
            }

            //повторяем исходный запрос
            auth.setToken(accessToken, refreshToken);
            return axiosInstance(config);
        }

        return Promise.reject(error);
    }
);

const paramsSerializer = (pars: string) => stringify(pars, { arrayFormat: 'brackets' });

export type TSelectResponse = Promise<
    AxiosResponse<{
        result: Array<{ value: number; label: string; selected?: boolean }>;
    }>
>;
export type TSelectResponse2 = Promise<
    AxiosResponse<{
        result: Array<{ id: number; title: string; selected?: boolean }>;
    }>
>;

export interface IGetSelectParams {
    defaultSelects?: number[];
    searchText?: string;
    optionsLimit?: number;
}

const generateRequestsByPath = (path: string) => ({
    getList: (params: ISelectParams) => {
        const { sizePerPage, page, sortField, sortOrder, filters } = params;
        return axiosInstance.get(path, {
            params: { page, sizePerPage, sortField, sortOrder, filters },
            paramsSerializer,
        });
    },
    deleteOne: (id: number) => axiosInstance.delete(`${path}/${id}`),
    getOne: (id: number) => axiosInstance.get(`${path}/${id}`),
    updateOne: (id: number, data: object) => axiosInstance.patch(`${path}/${id}`, data),
    createOne: (data: object) => axiosInstance.post(`${path}`, data),
    getSelect: (params: IGetSelectParams): TSelectResponse =>
        axiosInstance.get(`${path}/select`, { params, paramsSerializer }),
});

export const authApi = {
    //прочитать user по сохраненному access токену
    loginByToken: () => axiosInstance.get('auth/login-by-token'),
    //войти в систему по логин/пароль
    login: ({ username, password }: { username: string; password: string }) =>
        axiosInstance.post('auth/login', { username, password }),
    //Логин по factor2 коду
    loginByFactor2Code: (code: string) => axiosInstance.post('auth/login-by-factor2-code', { code }),
    //Повторный запрос factor2 кода
    repeatFactor2Code: () => axiosInstance.post('auth/repeat-factor2-code'),
    //выход из системы
    logout: () => axiosInstance.post('auth/logout'),
    //Запросить смену пароля
    startResetPassword: (data: object) => axiosInstance.post(`auth/start-reset-password`, data),
    //Сменить пароль
    resetPassword: (data: object) => axiosInstance.post(`auth/reset-password`, data),
    //Проверить токен смены пароля
    checkResetToken: (data: object) => axiosInstance.post(`auth/check-reset-token`, data),
    //прочитать общедоступные регистрационные данные
    getPublicConfig: () => axiosInstance.get('auth/public-config'),
};

export const articlesApi = {
    ...generateRequestsByPath('articles'),
};

export const tagsApi = {
    ...generateRequestsByPath('tags'),
    getMultiSelect: (params: IGetSelectParams): TSelectResponse =>
        axiosInstance.get('tags/multiSelect', { params, paramsSerializer }),
};

//заявки
export const supportApi = {
    ...generateRequestsByPath('support'),
};

export const usersApi = {
    ...generateRequestsByPath('users'),
    getSingleSelect: (params: IGetSelectParams): TSelectResponse =>
        axiosInstance.get('users/singleSelect', { params, paramsSerializer }),
};

export const roleApi = {
    ...generateRequestsByPath('role'),
    getSectionList: () => axiosInstance.get(`role/sectionList`),
    getSelect: (params: IGetSelectParams): TSelectResponse2 =>
        axiosInstance.get(`role/select`, { params, paramsSerializer }),
};

export const securityApi = {
    //Прочитать
    get: () => axiosInstance.get(`/security`),
    //исправить
    update: (data: object) => axiosInstance.patch(`/security`, data),
};

export const lentaApi = {
    getList: (params: ISelectParams) => axiosInstance.get(`/articles`, { params, paramsSerializer }),
};

// *************************************************************************************************************
// Работа с файлами
//
type TCreatedFile = {
    success: 1 | 0;
    file?: { url: string; id: number };
};

//создать файл
//endpoint - контролер обработки запроса
const createFile = (file: File, endpoint: string): Promise<AxiosResponse<TCreatedFile>> => {
    const formData = new FormData();
    formData.append('files', file);

    return axiosInstance.post<TCreatedFile>(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const fileApi = {
    ...generateRequestsByPath('files'),
    //  image - картинки
    createImageFile: (file: File): Promise<AxiosResponse<TCreatedFile>> => createFile(file, 'files/image'),
};

export const transferApi = {
    //  css
    createTransferFile: (file: File): Promise<AxiosResponse<TCreatedFile>> => createFile(file, 'transfer/file'),
    //  import
    import: () => axiosInstance.post(`/transfer/import`),
};
