import { useQuery, useMutation, useQueryClient } from 'react-query';

import { roleApi as itemApi } from 'api';
import { TPermission } from 'types/permission.type';

export interface IItemContent {
    title: string;
    permissions: TPermission;
}

export interface IItem extends Partial<IItemContent> {
    id: number;
}

//ключ кеша
const queryKey = 'roleContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    const response = await itemApi.getOne(id);
    return response.data;
}
export function useRqItemRead(id: number) {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(id),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    const response = await itemApi.updateOne(id, content);
    return response.data;
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};

//*************************************************************************************************************
//добавить
type TCreateProps = { content: Partial<IItemContent> };
async function itemCreate({ content }: TCreateProps): Promise<IItem> {
    const response = await itemApi.createOne(content);
    return response.data;
}
export const useRqItemCreate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TCreateProps) => itemCreate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};

//список секций прав доступа
const keySectionList = 'sectionList';
type TSectionList = Array<{ id: number; name: string; title: string }>;

//*************************************************************************************************************
//читать список секций
async function sectionListRead(): Promise<TSectionList> {
    const response = await itemApi.getSectionList();
    return response.data;
}
export function useRqSectionListRead() {
    return useQuery({
        queryKey: [keySectionList],
        queryFn: () => sectionListRead(),
        staleTime: Infinity, //список статичен
    });
}
