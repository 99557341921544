import { useQuery, useMutation, useQueryClient } from 'react-query';

import { usersApi as itemApi } from 'api';

//статус аккаунта пользователя
export enum UserStatus {
    ACTIVE = 0, //активный
    LOCKED = 1, //заблокирован
}

//список статусов
export const userStatusList = [
    { id: UserStatus.ACTIVE, title: 'активный' },
    { id: UserStatus.LOCKED, title: 'заблокирован' },
];

export interface IItemContent {
    email: string;
    status: number;
    commonName: string;
    firstname: string;
    lastname: string;
    password: string;
    passwordCopy: string;
    role: { id: number };
}

export interface IItem extends Partial<IItemContent> {
    id: number;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const convertFromServer = (data: any): IItem => {
    return { ...data, password: '', passwordCopy: '' };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const convertToServer = (data: any): IItem => {
    return {
        ...data,
        ...(data.role && { roleId: data.role.id }), //требует одиночный id
    };
};

//ключ кеша
const queryKey = 'usersContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    return convertFromServer((await itemApi.getOne(id)).data);
}
export function useRqItemRead(id: number) {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(id),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    return convertFromServer((await itemApi.updateOne(id, convertToServer(content))).data);
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};

//*************************************************************************************************************
//добавить
type TCreateProps = { content: Partial<IItemContent> };
async function itemCreate({ content }: TCreateProps): Promise<IItem> {
    return (await itemApi.createOne(convertToServer(content))).data;
}
export const useRqItemCreate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TCreateProps) => itemCreate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
