import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const CenteredSpiner: React.FC = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress size="3rem" thickness={2} sx={{ color: 'secondary.dark' }} />
        </Box>
    );
};
