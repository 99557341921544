import InterWoffItalic from 'assets/fonts/Inter-Italic.woff';
import InterWoffItalic2 from 'assets/fonts/Inter-Italic.woff2';
import InterWoffBold from 'assets/fonts/Inter-Bold.woff';
import InterWoffBold2 from 'assets/fonts/Inter-Bold.woff2';
import InterWoffLight from 'assets/fonts/Inter-Light.woff';
import InterWoffLight2 from '../assets/fonts/Inter-Light.woff2';
import InterWoff from '../assets/fonts/Inter-Regular.woff';
import InterWoff2 from '../assets/fonts/Inter-Regular.woff2';

const fontInter = `@font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), local('Inter-Regular'), url(${InterWoff2}) format('woff2'), url(${InterWoff}) format('woff');          
        }
        
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Inter'), local('Inter-Light'), url(${InterWoffLight2}) format('woff2'), url(${InterWoffLight}) format('woff');          
        }
        
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Inter'), local('Inter-Bold'), url(${InterWoffBold2}) format('woff2'), url(${InterWoffBold}) format('woff');          
        }
        
         @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-display: swap;
          font-weight: 500;
          src: local('Inter'), local('Inter-Italic'), url(${InterWoffItalic2}) format('woff2'), url(${InterWoffItalic}) format('woff');          
        }`;

export default fontInter;
