import React, { useContext, useMemo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide, { SlideProps } from '@mui/material/Slide';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

type TVariant = 'success' | 'error';

type SnackbarMessage = {
    message: string;
    key: number;
    variant: TVariant;
};

type TState = {
    open: boolean;
    snackPack: readonly SnackbarMessage[];
    messageInfo?: SnackbarMessage;
};

const defaultState = { open: false, snackPack: [], messageInfo: undefined };

type TActions = {
    ctxSetMessage: (message: string, variant: TVariant) => void;
};

const notifyStateContext = React.createContext<TState>(defaultState);
const notifyActionsContext = React.createContext<TActions>({
    ctxSetMessage: () => null,
});

export function useNotifyStateContext() {
    return useContext(notifyStateContext);
}
export function useNotifyActionsContext() {
    return useContext(notifyActionsContext);
}

export const NotifyContextProvider: React.FC = ({ children }) => {
    const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>(defaultState.snackPack);
    const [open, setOpen] = React.useState(defaultState.open);
    const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(defaultState.messageInfo);

    React.useEffect(() => {
        if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }
    }, [snackPack, messageInfo, open]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    //*************************************************************************************************************
    //новое уведомление
    function setMessage(message: string, variant: TVariant) {
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime(), variant }]);
    }

    //список всех функции по работе с контекстом; будет положен в context в actions
    //создается только один раз при первом рендеринге, чтобы не было изменений в actions, вызывающих обновление контекста
    const actions = useMemo(() => ({ ctxSetMessage: setMessage }), []);

    //вернуть child компоненты, обернутые в context
    return (
        <notifyStateContext.Provider value={{ open, snackPack, messageInfo }}>
            <notifyActionsContext.Provider value={actions}>
                <Snackbar
                    key={messageInfo ? messageInfo.key : undefined}
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    TransitionComponent={SlideTransition}
                    TransitionProps={{ onExited: handleExited }}
                    message={messageInfo ? messageInfo.message : undefined}
                    action={
                        <React.Fragment>
                            <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    ContentProps={{
                        sx: {
                            bgcolor: messageInfo?.variant === 'error' ? 'error.main' : 'success.main',
                            width: { sm: '500px' },
                        },
                    }}
                />
                {children}
            </notifyActionsContext.Provider>
        </notifyStateContext.Provider>
    );
};
