import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import * as appConst from 'app.const';

export const useLayoutWidth = (drawerState: boolean) => {
    const theme = useTheme();
    const desktopMode = useMediaQuery(theme.breakpoints.up('sm'));

    return {
        desktopMode,
        layoutMarginLeft: desktopMode && drawerState ? `${appConst.drawerWidth}px` : 0,
        layoutWidth: desktopMode && drawerState ? `calc(100% - ${appConst.drawerWidth}px)` : '100%',
    };
};
