import React from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { Autocomplete, TextField, FormControl, FormHelperText, CircularProgress } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { AxiosResponse } from 'axios';

import { useIsMounted } from 'lib/hooks';
import { useNotification } from 'components/notify';
import { IGetSelectParams } from 'api';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IItem extends Record<string, any> {
    id: number;
}

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    placeholder?: string;
    api: (params: IGetSelectParams) => Promise<AxiosResponse<any, any>>;
    invalidText?: string;
    validText?: string;
    optionsLimit?: number;
    optionLabel?: string;
}

export const FormSingleSelect = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label = '',
    placeholder = 'Поиск',
    api,
    invalidText,
    validText = ' ',
    optionsLimit = 50, //ограничение на количество элементов в выпадающем списке; если 0, то без ограничений (зависит от сервера)
    optionLabel = 'title',
}: Props<T>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });
    const [options, setOptions] = React.useState<IItem[]>([]);
    const [inputValue, setInputValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const { notifyApiError } = useNotification();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();

    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    params: IGetSelectParams,
                    callbackSuccess: (results: IItem[]) => void,
                    callbackError: (error: any) => void
                ) => {
                    if (isMounted.current) setLoading(true);
                    api(params)
                        .then(({ data }) => {
                            callbackSuccess(data.result);
                        })
                        .catch((error) => callbackError(error))
                        .finally(() => {
                            if (isMounted.current) setLoading(false);
                        });
                },
                400
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    React.useEffect(() => {
        const defaultSelects = (value as IItem).id ? [(value as IItem).id] : [];
        fetch(
            { defaultSelects, searchText: inputValue, optionsLimit },
            (results) => {
                if (isMounted.current) setOptions(results);
            },
            (error) => notifyApiError(error)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, inputValue, fetch]);

    const isError = !!invalidText;

    return (
        <FormControl>
            <Autocomplete
                id={`${name}-SingleSelect`}
                freeSolo={false}
                clearOnBlur={false}
                value={(value as IItem).id ? (value as IItem) : null}
                onChange={(event, newValue) => onChange(newValue?.id ? newValue : { id: 0 })}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'reset') return;
                    setInputValue(newInputValue);
                }}
                options={options}
                getOptionLabel={(option) => option[optionLabel] || ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        placeholder={placeholder}
                        error={isError}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                isOptionEqualToValue={(i1: IItem, i2: IItem) => i1.id === i2.id}
                clearText="Очистить"
                closeText="Закрыть"
                loadingText="Загрузка..."
                noOptionsText="..."
                openText="Открыть"
                disableCloseOnSelect={true}
            />{' '}
            <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }} error={isError}>
                {isError ? invalidText : validText}
            </FormHelperText>
        </FormControl>
    );
};
