import React from 'react';
import { isUndefined } from 'lodash';
import { useLocation } from 'react-router-dom';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { CardContent } from '@mui/material';

import ErrorWarning from 'components/errors/ErrorWarning';
import { ViewHeader } from 'components/headers/ViewHeader';
import { CenteredSpiner } from 'components/loaders/CenteredSpiner';
import { StyledCard, StyledCardHeader } from 'components/styled/card';
import ItemForm from '../components/Form';
import { useRqItemRead } from '../queries';

const ItemEdit: React.FC = () => {
    const id = Number(useLocation().pathname.split('/').slice(-2)[0]);
    //чтение
    const rqItem = useRqItemRead(id);

    //isLoading из useQuery срабатывает, когда идет первоначальная загрузка данных
    if (rqItem.isLoading || rqItem.isFetching) return <CenteredSpiner />;
    if (rqItem.isError) return <ErrorWarning error={rqItem.error as Error} />;
    if (isUndefined(rqItem.data)) return null;

    return (
        <React.Fragment>
            <ViewHeader title="Редактировать публикацию" icon={<FormatAlignLeftIcon />} />
            <StyledCard>
                <StyledCardHeader title={`Публикация ID: ${id}`} />
                <CardContent>
                    {/* ключ key={`ItemForm-${id}-${Date.now()}`} будет вызывать принудительный рендер после сохранения для обновления значений контролов
                    используется, когда вызываем reset(), а не reset(data) */}
                    <ItemForm id={id} content={rqItem.data} mode="edit" />
                </CardContent>
            </StyledCard>
        </React.Fragment>
    );
};

export default ItemEdit;
