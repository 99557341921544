import React from 'react';

import { supportApi } from 'api';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { DataTable } from 'components/table';
import { ColumnDescription } from 'components/table/types';
import { ItemActionButtons } from 'components/table/ItemActionButtons';
import { useAppStateContext } from 'AppContext';

export const SupportList = () => {
    //данные из контекста
    const { permissions } = useAppStateContext();
    const userAccess = permissions && permissions.support;

    const columnsDescription: ColumnDescription[] = [
        {
            disableSort: true,
            dataField: '#table-actions',
            columnTitle: 'Действия',
            width: '7em',
            align: 'center',
            format: (listControl) => (
                <ItemActionButtons listControl={listControl} isEdit={userAccess?.update === true} />
            ),
        },
        {
            dataField: 'id',
            columnTitle: 'ID',
            width: '7em',
        },
        {
            dataField: 'status',
            columnTitle: 'Название',
            width: '10em',
            textOverflow: true,
        },
        {
            dataField: 'subject',
            columnTitle: 'Тема',
            width: '25em',
            textOverflow: true,
        },
        {
            dataField: 'text',
            columnTitle: 'Текст',
            width: '25em',
            textOverflow: true,
        },
        {
            dataField: 'create',
            columnTitle: 'Дата создания',
            width: '12em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
        {
            dataField: 'update',
            columnTitle: 'Дата обновления',
            width: '12em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
        {
            dataField: 'name',
            columnTitle: 'Автор',
            width: '15em',
            textOverflow: true,
        },
        {
            dataField: 'email',
            columnTitle: 'Почта',
            width: '15em',
            textOverflow: true,
        },
    ];

    return (
        <DataTable
            listStorageName="support"
            title="Список заявок"
            columnsDescription={columnsDescription}
            api={supportApi.getList}
            actions={[]}
            defaultSelectParams={{
                page: 0,
                rowsPerPage: 10,
                sortField: 'id',
                sortOrder: 'desc',
            }}
        />
    );
};
