import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { isEmpty, isUndefined } from 'lodash';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { CardMedia, Box, FormHelperText, FormControl } from '@mui/material';

import { fileApi } from 'api';
import { StyledFormControl, StyledFormLabel } from 'components/styled/form';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    invalidText?: string;
    validText?: string;
    placeholder?: string;
    disabled?: boolean;
    clearValueOnError?: boolean;
}

export const FormDropzone = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label,
    placeholder = '',
    invalidText,
    validText = ' ',
    disabled = false,
    clearValueOnError = true,
}: Props<T>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });
    const [imageUrl, setImage] = useState<string>();

    useEffect(() => {
        if (!isUndefined(value)) {
            setImage(value);
        }
    }, [value]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: 'image/*',
        onDrop: async (file) => {
            const { data } = await fileApi.createImageFile(file[0]);
            if (!isUndefined(data.file)) {
                setImage(data.file.url);
                //если id созданного файла не используется
                onChange(data.file.url);
            }
        },
        disabled,
    });

    const handleImgError = () => {
        setImage(undefined);
        if (clearValueOnError) onChange('');
    };

    const isError = !!invalidText;

    return (
        <FormControl>
            <StyledFormControl invalidText={invalidText}>
                <StyledFormLabel>{label}</StyledFormLabel>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box
                        sx={{
                            height: '30em',
                            cursor: 'pointer',
                            m: 1,
                        }}
                    >
                        {!isUndefined(imageUrl) && !isEmpty(imageUrl) ? (
                            <CardMedia
                                component="img"
                                image={imageUrl}
                                alt="Картинка не доступна"
                                onError={() => handleImgError()}
                                sx={{ height: '100%', objectFit: 'contain' }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {placeholder}
                            </Box>
                        )}
                    </Box>
                </div>
            </StyledFormControl>

            <FormHelperText id={`${name}-helper-text`} sx={{ mt: 0, mb: 0 }} error={isError}>
                {isError ? invalidText : validText}
            </FormHelperText>
        </FormControl>
    );
};
