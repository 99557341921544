import React from 'react';
import { useNavigate } from 'react-router-dom';

import { roleApi } from 'api';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { DataTable } from 'components/table';
import { ColumnDescription, TAction } from 'components/table/types';
import { ItemActionButtons } from 'components/table/ItemActionButtons';
import { useAppStateContext } from 'AppContext';

export const RoleList = () => {
    const navigate = useNavigate();
    //данные из контекста
    const { permissions } = useAppStateContext();
    const userAccess = permissions && permissions.roles;

    const columnsDescription: ColumnDescription[] = [
        {
            disableSort: true,
            dataField: '#table-actions',
            columnTitle: 'Действия',
            width: '7em',
            align: 'center',
            format: (listControl) => (
                <ItemActionButtons
                    listControl={listControl}
                    apiDeleteOne={roleApi.deleteOne}
                    isEdit={userAccess?.update === true}
                    isDelete={
                        userAccess?.delete === true &&
                        listControl.row.name !== 'admin' &&
                        listControl.row.name !== 'author'
                    }
                />
            ),
        },
        {
            dataField: 'id',
            columnTitle: 'ID',
            width: '7em',
        },
        {
            dataField: 'title',
            columnTitle: 'Название',
            width: '45em',
            textOverflow: true,
        },
        {
            dataField: 'createdAt',
            columnTitle: 'Дата создания',
            width: '18em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
        {
            dataField: 'updatedAt',
            columnTitle: 'Дата редактирования',
            width: '18em',
            format: ({ value }) => formatDate(value, config.formats.time),
        },
    ];

    //Добавить действия
    const actions: TAction[] = [];
    if (userAccess?.create === true)
        actions.push({
            name: 'Добавить роль',
            handle: () => navigate(`/admin/role/create`),
        });

    return (
        <DataTable
            listStorageName="role"
            title="Список ролей"
            columnsDescription={columnsDescription}
            api={roleApi.getList}
            actions={actions}
            defaultSelectParams={{
                page: 0,
                rowsPerPage: 20,
                sortField: 'createdAt',
                sortOrder: 'desc',
            }}
        />
    );
};
