import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { CardContent } from '@mui/material';

import { ViewHeader } from 'components/headers/ViewHeader';
import { StyledCard, StyledCardHeader } from 'components/styled/card';
import ItemForm from '../components/Form';

const ItemEdit: React.FC = () => {
    return (
        <React.Fragment>
            <ViewHeader title="Добавить пользователя" icon={<PersonAddIcon />} />
            <StyledCard>
                <StyledCardHeader title="Новый пользователь" />
                <CardContent>
                    <ItemForm mode="create" />
                </CardContent>
            </StyledCard>
        </React.Fragment>
    );
};

export default ItemEdit;
